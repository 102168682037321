import React from 'react'
import { Query } from '@apollo/client/react/components'
import { Skeleton } from 'antd'
import { QUERY_MERCHANT_STORE_STATS } from './HomeQueries'

import MerchantsList from './MerchantsList'

const MerchantsContainer = ({ client }) => {
  return (
    <>
      <div className='merchants'>
        <Query query={QUERY_MERCHANT_STORE_STATS}>
          {({ data, loading }) => {
            if (loading) {
              return <Skeleton active />
            }
            if (data && data.view_merchant_store_status) {
              return (
                <MerchantsList
                  merchants={data.view_merchant_store_status}
                  client={client}
                />
              )
            }
            return null
          }}
        </Query>
      </div>
    </>
  )
}

export default MerchantsContainer
