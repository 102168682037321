import React from 'react'
import { Subscription } from '@apollo/client/react/components'
import { QUERY_LATEST_MERCHANT_ORDERS } from './OrderQueries'
import OrdersList from './OrdersList'
import { Skeleton } from 'antd'

interface OrdersContainerProps {
  client: any
  slug: string
}

const OrdersContainer = ({ client, slug }: OrdersContainerProps) => {
  return (
    <>
      <div className='orders-filter'></div>
      <div className='orders'>
        <Subscription
          subscription={QUERY_LATEST_MERCHANT_ORDERS}
          variables={{ slug }}
        >
          {({ data, loading }) => {
            if (loading) {
              return <Skeleton paragraph={{ rows: 10 }} active loading />
            }
            if (data && data.orders) {
              return (
                <OrdersList
                  orders={data.orders}
                  merchant={data.merchants[0]}
                  client={client}
                />
              )
            }
            return null
          }}
        </Subscription>
      </div>
    </>
  )
}

export default OrdersContainer
