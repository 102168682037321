import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Menu from 'antd/lib/menu'
import Tabs from 'antd/lib/tabs'
import Empty from 'antd/lib/empty'
import Space from 'antd/lib/space'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Dropdown from 'antd/lib/dropdown'
import Statistic from 'antd/lib/statistic'
import PageHeader from 'antd/lib/page-header'
import Descriptions from 'antd/lib/descriptions'
import JSONView from '../Utils/JSONView'
import LocationsTable from './LocationsTable'
import OrdersContainer from './OrdersContainer'
import MerchantSettings from './MerchantSettings'
import HomeOutlined from '@ant-design/icons/HomeOutlined'
import CustomDeliveryPricing from './CustomDeliveryPricing'
import ShoppingOutlined from '@ant-design/icons/ShoppingOutlined'
import MerchantKPI from '../KPI/MerchantKPI'
import { useHistory } from 'react-router-dom'
import { useMerchantAuth } from '../Utils/auth'

const DOMAIN_SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX
const { TabPane } = Tabs
const MerchantDetails = (props) => {
  const {
    merchant,
    tab,
    merchant: { stores }
  } = props

  const history = useHistory()
  const switchToTab = tab || 'overview'

  const changePath = (path: string) => {
    history.push(`/merchants/${merchant.slug}/${path}`)
  }

  return (
    <>
      <Row justify='center'>
        <Col span={6}>
          <Statistic
            title='Stores'
            value={stores.length}
            prefix={<HomeOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title='Orders'
            value={merchant.orders_aggregate.aggregate.count}
            prefix={<ShoppingOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title='Delivery'
            value={merchant.delivery_orders.aggregate.count}
            prefix={<ShoppingOutlined />}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title='Pickup'
            value={merchant.pickup_orders.aggregate.count}
            prefix={<ShoppingOutlined />}
          />
        </Col>
      </Row>
      <Divider />
      <Tabs
        defaultActiveKey={switchToTab}
        onChange={(activeKey) => changePath(activeKey)}
      >
        <TabPane tab='Overview' key='overview'>
          <MerchantKPI merchantId={merchant.id} />
        </TabPane>
        <TabPane tab='Stores' key='stores'>
          <LocationsTable merchant={merchant} client={props.client} />
        </TabPane>
        <TabPane tab='Orders' key='orders'>
          <OrdersContainer client={props.client} slug={merchant.slug} />
        </TabPane>
        <TabPane tab='Settings/Features' key='settings'>
          <Descriptions>
            <Descriptions.Item>
              <MerchantSettings
                merchant={merchant}
                client={props.client}
                refetch={props.refetch}
              />
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab='Raw Settings' key='raw_settings'>
          <JSONView src={merchant} />
        </TabPane>
        <TabPane tab='Raw Store Settings' key='store_settings'>
          <JSONView src={merchant.stores} />
        </TabPane>
        <TabPane tab='Customize Delivery Pricing' key='delivery_pricing'>
          <CustomDeliveryPricing
            priceMatrices={merchant.price_matrices || []}
            id={merchant.id}
            client={props.client}
            stores={stores}
            refetch={props.refetch}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

const MerchantInfo = (props) => {
  const { merchant, tab, client, refetch } = props
  const api_key = localStorage.getItem('apiKey') || ''
  const merchantSeoUrl = `https://${merchant.slug}.${DOMAIN_SUFFIX}/merchant/merchant_seo/export?token=${api_key}`
  const storeSeoUrl = `https://${merchant.slug}.${DOMAIN_SUFFIX}/merchant/store_seo/export?token=${api_key}`
  const productSeoUrl = `https://${merchant.slug}.${DOMAIN_SUFFIX}/merchant/product_seo/export?token=${api_key}`

  const { merchantUserSSOUrl } = useMerchantAuth()

  if (merchant === null) {
    return <Empty />
  }

  const handleMerchantAuth = async (merchantId: string, userId: string) => {
    window.open(await merchantUserSSOUrl({ merchantId, userId }), '_blank')
  }

  const UserDropdown = ({
    users
  }: {
    users: {
      id: string
      email: string
      role: string
      store: { slug: string }
    }[]
  }) => {
    return (
      <Menu>
        {users.map((u) => (
          <Menu.Item
            onClick={() => {
              handleMerchantAuth(merchant.id, u.id)
            }}
            style={{
              padding: 0,
              paddingLeft: '20px',
              height: 'auto',
              lineHeight: '28px'
            }}
          >
            [<strong>{u.role}</strong>]
            {u.store && <strong>{u.store.slug}</strong>} - ({u.email})
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  return (
    <>
      <Row justify='space-between' align='middle'>
        <Col>
          <Space>
            <PageHeader title={merchant.name} />

            <Button type='primary'>
              <a
                href={`https://${merchant.slug}.${DOMAIN_SUFFIX}/order`}
                target='blank'
              >
                Visit site
              </a>
            </Button>

            <div>
              <strong>Partner ID:</strong> {merchant.id}
            </div>
            <div>
              <strong>Published at:</strong>{' '}
              {merchant.published_at || 'NOT PUBLISHED'}
            </div>
          </Space>
        </Col>
        <Col>
          <Space>
            <Dropdown
              overlay={<UserDropdown users={merchant.users} />}
              placement='bottomLeft'
            >
              <Button type='primary'>Sign In to Dashboard</Button>
            </Dropdown>
          </Space>
        </Col>
      </Row>
      <Row justify='start' gutter={[10, 10]}>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          DOWNLOAD DESCRIPTIONS
        </Col>
        <Col>
          <Button href={merchantSeoUrl} target='_blank' type='primary'>
            Partner Descriptions
          </Button>
        </Col>
        <Col>
          <Button href={storeSeoUrl} target='_blank' type='primary'>
            Store Descriptions
          </Button>
        </Col>
        <Col>
          <Button href={productSeoUrl} target='_blank' type='primary'>
            Product Descriptions
          </Button>
        </Col>
      </Row>

      <MerchantDetails
        merchant={merchant}
        client={client}
        refetch={refetch}
        tab={tab}
      />
    </>
  )
}

export default MerchantInfo
