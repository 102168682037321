import React, { useState } from 'react'
import {
  Skeleton,
  PageHeader,
  Menu,
  Dropdown,
  Button,
  Col,
  Space,
  Switch
} from 'antd'
import NumberFormat from 'react-number-format'
import { Query } from '@apollo/client/react/components'
import {
  QUERY_COMBINED_MONTHLY_KPI,
  QUERY_AVAILABLE_KPI_MONTHS
} from './KPIQueries'
import strftime from 'strftime'
import { startOfMonth, formatISO, parseISO, sub } from 'date-fns'
import { CaretDownOutlined } from '@ant-design/icons'
import './kpi.css'
import { useHistory } from 'react-router'
import { useQuery } from '@apollo/client'

const DISPLAY_MONTHS = 6

const sector_map = {
  bst: 'Bakeries & Sweet Treats',
  bba: 'Bars, Breweries & Alcohol',
  ccs: 'Cafes & Coffee Shops',
  dgp: 'Delis, Groceries & Provisions',
  qsr: 'QSR / Fast Casual',
  res: 'Restaurants',
  htl: 'Hotels'
}

const sector_keys = Object.keys(sector_map)
const sectors = sector_keys.map((k) => sector_map[k])

const asThousands = (value) => {
  return (
    <NumberFormat
      decimalScale={0}
      thousandSeparator={true}
      value={value}
      displayType='text'
    />
  )
}

const emptyKpiRow = () => <td colSpan={DISPLAY_MONTHS + 1} />

const kpiSectionRow = (text) => (
  <td className='kpi-header kpi-description' colSpan={DISPLAY_MONTHS + 1}>
    {text}
  </td>
)

const kpiSubsectionRow = (text) => (
  <td className='kpi-subheader' colSpan={DISPLAY_MONTHS + 1}>
    {text}
  </td>
)

const formatMonth = (date) => formatISO(date, { representation: 'date' })

type StampAppData = {
  month: string
  year: string
  merchants: number
  stores: number
  gtv: number
  gtv_offline: number
  gmv: number
  gmv_offline: number
  count: number
  count_offline: number
  pickup: number
  pickup_offline: number
  self_fulfilled: number
  self_fulfilled_offline: number
}

const KPITable = ({ to, client }) => {
  const toMonth = parseISO(to)
  const from = formatMonth(sub(toMonth, { months: DISPLAY_MONTHS - 1 }))

  const { loading, data } = useQuery(QUERY_COMBINED_MONTHLY_KPI, {
    client: client,
    fetchPolicy: 'no-cache',
    variables: {
      from,
      to
    }
  })

  const formatKpiRow = (description, kpis, render, opt: any = {}) => {
    let extraClasses = []

    if (opt.total) {
      extraClasses.push('kpi-total')
    }

    if (description.match('StampApp') || description.match('Slerp')) {
      extraClasses.push('subitem -show')
    }

    return (
      <>
        <td className={`kpi-description ${extraClasses.join(' ')}`}>
          {description}
        </td>
        {Object.keys(kpis).map((month) => (
          <td className={extraClasses.join(' ')}>{render(kpis[month])}</td>
        ))}
      </>
    )
  }

  if (loading) {
    return <Skeleton paragraph={{ rows: 30 }} active loading />
  }

  if (data && data.totals && data.by_sector && data.monthly_stampapp_bins) {
    let monthly_kpi = {}

    const StampAppHistorical = data.monthly_stampapp_bins

    data.totals.forEach((kpi) => {
      let by_sector = {}

      data.by_sector
        .filter((sector_data) => {
          return sector_data.month === kpi.month
        })
        .forEach((sector_data) => {
          by_sector[sector_data.sector] = {
            gmv: sector_data.gmv,
            ave: sector_data.gmv / sector_data.count,
            pct: sector_data.gmv / kpi.gmv
          }
        })

      let sector_ave = sector_keys.map((sector) =>
        by_sector[sector] ? by_sector[sector].ave / 1.2 : 0
      )
      let sector_gmv = sector_keys.map((sector) =>
        by_sector[sector] ? by_sector[sector].gmv : 0
      )
      let sector_pct = sector_keys.map((sector) =>
        by_sector[sector] ? by_sector[sector].pct * 100 : 0
      )

      // @ts-ignore
      if (by_sector[null]) {
        // @ts-ignore
        sector_ave.push(by_sector[null].ave / 1.2)

        // @ts-ignore
        sector_gmv.push(by_sector[null].gmv)

        // @ts-ignore
        sector_pct.push(by_sector[null].pct * 100)
      }

      const [saMonth] = StampAppHistorical.filter(
        (sa) => sa.month === kpi.month
      )

      const stampAppKPI: StampAppData = saMonth?.data

      const sector_ave_total = kpi.gmv / kpi.count / 1.2
      const sector_ave_total_stampapp =
        ((stampAppKPI?.gmv || 0) + (stampAppKPI?.gmv_offline || 0)) /
        ((stampAppKPI?.count || 0) + (stampAppKPI?.count_offline || 0)) /
        1.2
      const sector_ave_combined_total =
        (sector_ave_total + sector_ave_total_stampapp) / 2

      monthly_kpi[kpi.month] = {
        month: strftime('%b %y', new Date(kpi.month)),
        sales_slerp: kpi.slerp_fee,
        sales_courier: kpi.courier_fee,
        total_income: kpi.slerp_fee + kpi.courier_fee,
        commission_arr: kpi.slerp_fee * 12,
        gtv: kpi.gtv,
        stampapp_gtv: stampAppKPI?.gtv || 0,
        stampapp_gtv_offline: stampAppKPI?.gtv_offline || 0,
        gmv: kpi.gmv,
        stampapp_gmv: stampAppKPI?.gmv || 0,
        stampapp_gmv_offline: stampAppKPI?.gmv_offline || 0,
        last_mile: kpi.last_mile,
        pickup: kpi.pickup,
        table_order: kpi.table_order,
        stampapp_pickup: stampAppKPI?.pickup || 0,
        stampapp_pickup_offline: stampAppKPI?.pickup_offline || 0,
        self_fulfilled: kpi.self_fulfilled,
        stampapp_self_fulfilled: stampAppKPI?.self_fulfilled || 0,
        stampapp_self_fulfilled_offline:
          stampAppKPI?.self_fulfilled_offline || 0,
        fulfill_type_total: kpi.last_mile + kpi.pickup + kpi.self_fulfilled,
        asap: kpi.asap,
        ssd: kpi.ssd,
        pre: kpi.pre,
        order_type_total: kpi.asap + kpi.ssd + kpi.pre,
        ave_del: kpi.courier_fee / kpi.last_mile,
        merchants: kpi.merchants,
        stampapp_merchants: stampAppKPI?.merchants || 0,
        stores: kpi.stores,
        stampapp_stores: stampAppKPI?.stores || 0,
        count: kpi.count,
        stampapp_count: stampAppKPI?.count || 0,
        stampapp_count_offline: stampAppKPI?.count_offline || 0,
        sector_ave,
        sector_gmv,
        sector_pct,
        sector_ave_total,
        sector_ave_total_stampapp,
        sector_ave_combined_total
      }
    })

    const rows = []

    rows.push(
      <>
        <td className='kpi-header' width='20%'></td>
        {Object.keys(monthly_kpi).map((month) => (
          <td className='kpi-header' width='10%'>
            {monthly_kpi[month].month}
          </td>
        ))}
      </>
    )

    rows.push(
      kpiSectionRow('Slerp and StampApp'),
      formatKpiRow('No of Partners', monthly_kpi, (kpi) =>
        asThousands(kpi.merchants + kpi.stampapp_merchants)
      ),
      formatKpiRow('No of Partners (Slerp)', monthly_kpi, (kpi) =>
        asThousands(kpi.merchants)
      ),
      formatKpiRow('No of Partners (StampApp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_merchants)
      ),
      formatKpiRow('No of Outlets', monthly_kpi, (kpi) =>
        asThousands(kpi.stores + kpi.stampapp_stores)
      ),
      formatKpiRow('No of Outlets (Slerp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stores)
      ),
      formatKpiRow('No of Outlets (StampApp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_stores)
      ),
      formatKpiRow('GTV', monthly_kpi, (kpi) =>
        asThousands(kpi.gtv + kpi.stampapp_gtv + kpi.stampapp_gtv_offline)
      ),
      formatKpiRow('GTV (Slerp)', monthly_kpi, (kpi) => asThousands(kpi.gtv)),
      formatKpiRow('GTV (StampApp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_gtv)
      ),
      formatKpiRow('GTV (StampApp Offline)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_gtv_offline)
      ),
      formatKpiRow('GMV', monthly_kpi, (kpi) =>
        asThousands(kpi.gmv + kpi.stampapp_gmv + kpi.stampapp_gmv_offline)
      ),
      formatKpiRow('GMV (Slerp)', monthly_kpi, (kpi) => asThousands(kpi.gmv)),
      formatKpiRow('GMV (StampApp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_gmv)
      ),
      formatKpiRow('GMV (StampApp Offline)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_gmv_offline)
      ),
      formatKpiRow('No of Transactions', monthly_kpi, (kpi) =>
        asThousands(kpi.count + kpi.stampapp_count + kpi.stampapp_count_offline)
      ),
      formatKpiRow('No of Transactions (Slerp)', monthly_kpi, (kpi) =>
        asThousands(kpi.count)
      ),
      formatKpiRow('No of Transactions (StampApp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_count)
      ),
      formatKpiRow(
        'No of Transactions (StampApp Offline)',
        monthly_kpi,
        (kpi) => asThousands(kpi.stampapp_count_offline)
      ),
      formatKpiRow('Average Basket Size Total', monthly_kpi, (kpi) =>
        kpi.sector_ave_combined_total.toFixed(2)
      ),
      formatKpiRow('Average Basket Size Slerp (Excl VAT)', monthly_kpi, (kpi) =>
        kpi.sector_ave_total.toFixed(2)
      ),
      formatKpiRow(
        'Average Basket Size StampApp (Excl VAT)',
        monthly_kpi,
        (kpi) => kpi.sector_ave_total_stampapp.toFixed(2)
      ),
      emptyKpiRow(),
      kpiSectionRow('Slerp'),

      formatKpiRow('Commission & Fees', monthly_kpi, (kpi) =>
        asThousands(kpi.sales_slerp)
      ),
      formatKpiRow('Courier', monthly_kpi, (kpi) =>
        asThousands(kpi.sales_courier)
      ),
      formatKpiRow('Total Income', monthly_kpi, (kpi) =>
        asThousands(kpi.total_income)
      ),
      emptyKpiRow(),
      formatKpiRow('Commission ARR', monthly_kpi, (kpi) =>
        asThousands(kpi.sales_slerp * 12)
      ),
      emptyKpiRow(),
      kpiSectionRow('Per-transaction Analysis'),
      kpiSubsectionRow('Fulfillment Type'),
      formatKpiRow('Click & Collect', monthly_kpi, (kpi) =>
        asThousands(
          kpi.pickup + kpi.stampapp_pickup + kpi.stampapp_pickup_offline
        )
      ),
      formatKpiRow('Click & Collect (Slerp)', monthly_kpi, (kpi) =>
        asThousands(kpi.pickup)
      ),
      formatKpiRow('Click & Collect (StampApp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_pickup)
      ),
      formatKpiRow('Click & Collect (StampApp Offline)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_pickup_offline)
      ),
      formatKpiRow('Delivery', monthly_kpi, (kpi) =>
        asThousands(
          kpi.self_fulfilled +
            kpi.stampapp_self_fulfilled +
            kpi.self_fulfilled_offline
        )
      ),
      formatKpiRow('Slerp Courier Partner', monthly_kpi, (kpi) =>
        asThousands(kpi.last_mile)
      ),
      formatKpiRow('Self Delivery)', monthly_kpi, (kpi) =>
        asThousands(kpi.self_fulfilled)
      ),
      formatKpiRow('Self Delivery / 3PL (StampApp)', monthly_kpi, (kpi) =>
        asThousands(kpi.stampapp_self_fulfilled)
      ),
      formatKpiRow(
        'Self Delivery / 3PL (StampApp Offline)',
        monthly_kpi,
        (kpi) => asThousands(kpi.stampapp_self_fulfilled_offline)
      ),
      formatKpiRow('Order at Table', monthly_kpi, (kpi) =>
        asThousands(kpi.table_order || 0)
      ),
      formatKpiRow(
        'Total',
        monthly_kpi,
        (kpi) => asThousands(kpi.fulfill_type_total),
        {
          total: true
        }
      ),
      formatKpiRow('Average Slerp Courier Partner Income', monthly_kpi, (kpi) =>
        kpi.ave_del.toFixed(2)
      ),
      emptyKpiRow()
    )

    rows.push(
      kpiSubsectionRow('Order Type'),
      formatKpiRow('ASAP', monthly_kpi, (kpi) => asThousands(kpi.asap)),
      formatKpiRow('Scheduled Same Day', monthly_kpi, (kpi) =>
        asThousands(kpi.ssd)
      ),
      formatKpiRow('Preorder', monthly_kpi, (kpi) => asThousands(kpi.pre)),
      formatKpiRow('Order at Table', monthly_kpi, (kpi) =>
        asThousands(kpi.table_order || 0)
      ),
      formatKpiRow(
        'Total',
        monthly_kpi,
        (kpi) => asThousands(kpi.order_type_total),
        {
          total: true
        }
      ),
      emptyKpiRow()
    )

    rows.push(kpiSubsectionRow('Average Basket Size by Sector'))
    sectors.concat('Unassigned').forEach((sector, i) => {
      rows.push(
        formatKpiRow(sector, monthly_kpi, (kpi) =>
          kpi.sector_ave[i] ? kpi.sector_ave[i].toFixed(2) : ''
        )
      )
    })
    rows.push(
      formatKpiRow(
        'Total',
        monthly_kpi,
        (kpi) => kpi.sector_ave_total.toFixed(2),
        { total: true }
      )
    )

    rows.push(kpiSubsectionRow('GMV by Sector'))
    sectors.concat('Unassigned').forEach((sector, i) => {
      rows.push(
        formatKpiRow(sector, monthly_kpi, (kpi) =>
          kpi.sector_ave[i] ? asThousands(kpi.sector_gmv[i]) : ''
        )
      )
    })
    rows.push(
      formatKpiRow('Total', monthly_kpi, (kpi) => asThousands(kpi.gmv), {
        total: true
      })
    )

    rows.push(kpiSubsectionRow('GMV by Sector'))
    sectors.concat('Unassigned').forEach((sector, i) => {
      rows.push(
        formatKpiRow(sector, monthly_kpi, (kpi) =>
          kpi.sector_ave[i] ? `${kpi.sector_pct[i].toFixed(2)}%` : ''
        )
      )
    })
    rows.push(
      formatKpiRow('Total', monthly_kpi, (kpi) => '100%', { total: true })
    )

    return (
      <table className='kpi-table'>
        <tbody>
          {rows.map((row) => (
            <tr>{row}</tr>
          ))}
        </tbody>
      </table>
    )
  }
}

const CombinedMonthlyKPI = ({ client }) => {
  const [displayToMonth, setDisplayToMonth] = useState(
    formatMonth(startOfMonth(new Date()))
  )

  const history = useHistory()

  return (
    <>
      <Col span={20} offset={1}>
        <PageHeader title='Slerp KPIs' />

        <Query query={QUERY_AVAILABLE_KPI_MONTHS}>
          {({ data, loading }) => {
            if (loading) {
              return <Skeleton paragraph={{ rows: 1 }} active loading />
            }

            if (data && data.months) {
              const availableMonths = data.months.map((d) => d.month).reverse()

              let intervals = {}
              for (let i = 0; i < availableMonths.length; i += DISPLAY_MONTHS) {
                const slice = availableMonths.slice(i, i + DISPLAY_MONTHS)
                intervals[slice[0]] = [
                  strftime('%b %y', new Date(slice[slice.length - 1])),
                  strftime('%b %y', new Date(slice[0]))
                ]
              }

              return (
                <>
                  <Space>
                    <IntervalPicker
                      intervals={intervals}
                      setKey={setDisplayToMonth}
                      selectedKey={displayToMonth}
                    />
                    <Space>
                      <Switch
                        defaultChecked={true}
                        onChange={() => {
                          history.push('/kpi')
                        }}
                      />
                      With stampapp
                    </Space>
                  </Space>
                  {displayToMonth && (
                    <KPITable to={displayToMonth} client={client} />
                  )}
                </>
              )
            }
          }}
        </Query>
      </Col>
    </>
  )
}

const IntervalPicker = ({ intervals, setKey, selectedKey }) => {
  const onSelect = ({ key }) => {
    setKey(key)
  }

  const renderInterval = (key) => {
    return intervals[key] ? `${intervals[key][0]} - ${intervals[key][1]}` : ''
  }

  const menu = (
    <Menu onClick={onSelect}>
      {Object.keys(intervals).map((key) => (
        <Menu.Item key={key}>{renderInterval(key)}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu}>
      <Button>
        {renderInterval(selectedKey) || 'Select interval'}
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  )
}

export default CombinedMonthlyKPI
