import { Query } from '@apollo/client/react/components'
import { Skeleton } from 'antd'
import { QUERY_FREE_ORDER_COMMISSION_PLANS } from '../MerchantQueries'
import FreeOrderCommissionPlan from './FreeOrderCommissionPlan'
import { Merchant } from '../types'
import { ApolloClient } from '@apollo/client'

interface FreeOrderCommissionContainerProps {
  client: ApolloClient<any>
  merchant: Merchant
}

const FreeOrderCommissionContainer = ({
  client,
  merchant
}: FreeOrderCommissionContainerProps) => {
  return (
    <>
      <Query query={QUERY_FREE_ORDER_COMMISSION_PLANS}>
        {({ data, loading }) => {
          if (loading) {
            return <Skeleton active />
          }
          if (data && data.zero_order_rates) {
            const initialPlan = data.zero_order_rates.find(
              (x) => x.id === merchant.zero_order_rate_id
            )
            return (
              <FreeOrderCommissionPlan
                plans={data.zero_order_rates}
                initialPlan={initialPlan}
                merchant={merchant}
                client={client}
              />
            )
          }
          return null
        }}
      </Query>
    </>
  )
}

export default FreeOrderCommissionContainer
