import { AutoComplete, Typography } from 'antd'
import { gql, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const SEARCH_PARTNER = gql`
  query SearchPartner($search: String!) {
    merchants(
      where: { name: { _ilike: $search } }
      order_by: { inserted_at: desc }
      limit: 10
    ) {
      name
      slug
      inserted_at
    }
  }
`

const searchCache = (): string[] => {
  try {
    return JSON.parse(
      localStorage.getItem('recentSearches') || '[]'
    ) as string[]
  } catch {
    return []
  }
}

const RecentSearches = () => {
  const cache = searchCache()
  const recent = [...new Set(cache)]

  return (
    <div>
      {recent.length > 0 && (
        <Typography.Text type='secondary' style={{ fontSize: '0.75rem' }}>
          Recent searches:{' '}
        </Typography.Text>
      )}
      {recent &&
        recent
          .reverse()
          .slice(0, 8)
          .map((key) => {
            return (
              <Typography.Text type='secondary' style={{ fontSize: '0.75rem' }}>
                <a href={`/merchants/${key}`} style={{ color: 'gray' }}>
                  {key}
                </a>{' '}
              </Typography.Text>
            )
          })}
    </div>
  )
}

const saveToCache = (key: string) => {
  const cache = searchCache()
  cache.push(key)
  localStorage.setItem('recentSearches', JSON.stringify(cache))
}

const FastSearch = ({ client }) => {
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState<{ value: string }[]>([])

  const history = useHistory()

  const { refetch } = useQuery(SEARCH_PARTNER, {
    variables: {
      search: `%${query}%`
    }
  })

  const onSearch = (searchText: string) => {
    setQuery(searchText)
    try {
      refetch({ search: `%${searchText}%` }).then((result) => {
        if (result.data && result.data.merchants) {
          setOptions(
            result.data.merchants.map((m) => ({
              label: (
                <>
                  {m.name} -{' '}
                  <Typography.Text type='secondary'>{m.slug}</Typography.Text>
                </>
              ),
              value: m.slug
            }))
          )
        }
      })
    } catch {
      console.log("Don't be a problematic component. Just catch all errors...")
    }
  }

  useEffect(() => {}, [query, refetch])

  return (
    <>
      <AutoComplete
        options={options}
        placeholder='Jump to partner...'
        onSearch={onSearch}
        onSelect={(value: string) => {
          saveToCache(value)
          history.push(`/merchants/${value}`)
        }}
        style={{
          width: '600px'
        }}
      />
      <RecentSearches />
    </>
  )
}

export default FastSearch
