import { Table } from 'antd'
import { uniq } from 'lodash'
import './PricingTable.css'

interface PricingTableProps {
  priceMatrix: {
    car: { [key: number]: string }
    bike: { [key: number]: string }
    motorbike: { [key: number]: string }
  }
}

const VEHICLE_TYPES = ['bike', 'motorbike', 'car']

const PricingTable = ({ priceMatrix }: PricingTableProps) => {
  const dataSource = VEHICLE_TYPES.reduce((acc, vehicle) => {
    return [
      ...acc,
      {
        vehicle:
          vehicle === 'car'
            ? 'Car/Small Van'
            : vehicle[0].toUpperCase() + vehicle.slice(1),
        ...priceMatrix[vehicle]
      }
    ]
  }, [])

  const allKeys = VEHICLE_TYPES.reduce((acc: string[], vehicle: string) => {
    return [...acc, ...Object.keys(priceMatrix[vehicle])]
  }, [])

  const distanceKeys = uniq(allKeys)
    .sort((a, b) => a - b)
    .map((key: string) => ({ key: key, dataIndex: key, title: key }))

  const columns = [
    { key: 'vehicle', title: 'Vehicle', dataIndex: 'vehicle' },
    ...distanceKeys
  ]

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      className='pricing-table'
    />
  )
}

export default PricingTable
