import React, { useState } from 'react'
import { Col, Skeleton, Menu, Dropdown, Button } from 'antd'
import NumberFormat from 'react-number-format'
import { Query } from '@apollo/client/react/components'
import {
  QUERY_MERCHANT_MONTHLY_KPI,
  QUERY_AVAILABLE_KPI_MONTHS
} from './KPIQueries'
import strftime from 'strftime'
import { startOfMonth, formatISO, parseISO, sub } from 'date-fns'
import { CaretDownOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'

import './kpi.css'

const DISPLAY_MONTHS = 6

const formatKpiRow = (description, kpis, render, opt: any = {}) => {
  let extraClasses = []

  if (opt.total) {
    extraClasses.push('kpi-total')
  }

  return (
    <>
      <td className={`kpi-description ${extraClasses.join(' ')}`}>
        {description}
      </td>
      {Object.keys(kpis).map((month) => (
        <td className={extraClasses.join(' ')}>{render(kpis[month])}</td>
      ))}
    </>
  )
}

const asThousands = (value) => {
  return (
    <NumberFormat
      decimalScale={0}
      thousandSeparator={true}
      value={value}
      displayType='text'
    />
  )
}

const emptyKpiRow = () => <td colSpan={DISPLAY_MONTHS + 1} />

const kpiSectionRow = (text) => (
  <td className='kpi-header kpi-description' colSpan={DISPLAY_MONTHS + 1}>
    {text}
  </td>
)

const kpiSubsectionRow = (text) => (
  <td className='kpi-subheader' colSpan={DISPLAY_MONTHS + 1}>
    {text}
  </td>
)

const formatMonth = (date) => formatISO(date, { representation: 'date' })

const KPITable = (props) => {
  const { to, merchantId } = props

  if (to == null) return null

  const toMonth = parseISO(to)
  const from = formatMonth(sub(toMonth, { months: DISPLAY_MONTHS - 1 }))

  return (
    <Query
      query={QUERY_MERCHANT_MONTHLY_KPI}
      variables={{ merchantId, from, to }}
    >
      {({ data, loading }) => {
        if (loading) {
          return <Skeleton paragraph={{ rows: 30 }} active loading />
        }

        if (data && data.totals) {
          let monthly_kpi = {}
          data.totals.forEach((total) => {
            const month = total.month
            const kpi = total.components

            const total_income = Math.floor(
              Number(kpi.slerp_fee) +
                Number(kpi.courier_fee) +
                Number(kpi.application_fee)
            )

            const totalDayCount = moment(month).isSame(moment(), 'month')
              ? moment().date() - 1
              : moment(month).daysInMonth()

            monthly_kpi[month] = {
              month: strftime('%b %y', new Date(month)),
              sales_slerp: Math.floor(kpi.slerp_fee),
              sales_courier: Math.floor(kpi.courier_fee),
              application_fee: Math.floor(kpi.application_fee),
              total_income: total_income,
              avg_income_per_day: total_income / totalDayCount,
              commission_arr: kpi.slerp_fee * 12,
              gtv: Math.floor(kpi.gtv),
              gmv: Math.floor(kpi.gmv),
              gmv_per_day: kpi.gmv / totalDayCount,
              avg_transactions_per_day: kpi.count / totalDayCount,
              last_mile: kpi.last_mile,
              pickup: kpi.pickup,
              table_order: kpi.table_order,
              self_fulfilled: kpi.self_fulfilled,
              fulfill_type_total:
                kpi.last_mile + kpi.pickup + kpi.self_fulfilled,
              asap: kpi.asap,
              ssd: kpi.ssd,
              pre: kpi.pre,
              order_type_total: kpi.asap + kpi.ssd + kpi.pre,
              ave_del: kpi.courier_fee / kpi.last_mile,
              merchants: kpi.merchants,
              count: kpi.count,
              sector_ave_total: kpi.gmv / kpi.count / 1.2,
              platform_count_web_mobile: kpi.platform_count_web_mobile,
              platform_count_web_desktop: kpi.platform_count_web_desktop,
              platform_count_ios_app: kpi.platform_count_ios_app,
              platform_count_android_app: kpi.platform_count_android_app,
              platform_count_unknown: kpi.platform_count_unknown,
              platform_count_total:
                Number(kpi.platform_count_web_mobile) +
                Number(kpi.platform_count_web_desktop) +
                Number(kpi.platform_count_ios_app) +
                Number(kpi.platform_count_ios_app) +
                Number(kpi.platform_count_unknown),
              platform_gmv_web_mobile: kpi.platform_gmv_web_mobile,
              platform_gmv_web_desktop: kpi.platform_gmv_web_desktop,
              platform_gmv_ios_app: kpi.platform_gmv_ios_app,
              platform_gmv_android_app: kpi.platform_gmv_android_app,
              platform_gmv_unknown: kpi.platform_gmv_unknown,
              platform_gmv_total:
                Number(kpi.platform_gmv_web_mobile) +
                Number(kpi.platform_gmv_web_desktop) +
                Number(kpi.platform_gmv_ios_app) +
                Number(kpi.platform_gmv_android_app) +
                Number(kpi.platform_gmv_unknown),
              platform_aov_web_mobile: kpi.platform_aov_web_mobile,
              platform_aov_web_desktop: kpi.platform_aov_web_desktop,
              platform_aov_ios_app: kpi.platform_aov_ios_app,
              platform_aov_android_app: kpi.platform_aov_android_app,
              platform_aov_unknown: kpi.platform_aov_unknown,
              platform_aov_total:
                Number(kpi.platform_aov_web_mobile) +
                Number(kpi.platform_aov_web_desktop) +
                Number(kpi.platform_aov_ios_app) +
                Number(kpi.platform_aov_android_app) +
                Number(kpi.platform_aov_unknown),
              platform_refunds_web_mobile: kpi.platform_refunds_web_mobile,
              platform_refunds_web_desktop: kpi.platform_refunds_web_desktop,
              platform_refunds_ios_app: kpi.platform_refunds_ios_app,
              platform_refunds_android_app: kpi.platform_refunds_android_app,
              platform_refunds_unknown: kpi.platform_refunds_unknown,
              platform_refunds_total:
                Number(kpi.platform_refunds_web_mobile) +
                Number(kpi.platform_refunds_web_desktop) +
                Number(kpi.platform_refunds_ios_app) +
                Number(kpi.platform_refunds_android_app) +
                Number(kpi.platform_refunds_unknown)
            }
          })

          const rows = []

          rows.push(
            <>
              <td className='kpi-header' width='20%'></td>
              {Object.keys(monthly_kpi).map((month) => (
                <td className='kpi-header' width='10%'>
                  {monthly_kpi[month].month}
                </td>
              ))}
            </>
          )

          rows.push(
            formatKpiRow('Income - Commissions', monthly_kpi, (kpi) =>
              asThousands(kpi.sales_slerp)
            ),
            formatKpiRow('Income - Slerp Courier Partner', monthly_kpi, (kpi) =>
              asThousands(kpi.sales_courier)
            ),
            formatKpiRow('Income - Slerp Pay', monthly_kpi, (kpi) =>
              asThousands(kpi.application_fee)
            ),
            formatKpiRow('Income - All', monthly_kpi, (kpi) =>
              asThousands(kpi.total_income)
            ),
            formatKpiRow('Avg Income per Day', monthly_kpi, (kpi) =>
              asThousands(kpi.avg_income_per_day)
            ),
            emptyKpiRow(),
            formatKpiRow('GTV', monthly_kpi, (kpi) => asThousands(kpi.gtv)),
            formatKpiRow('GMV', monthly_kpi, (kpi) => asThousands(kpi.gmv)),
            formatKpiRow('GMV per day', monthly_kpi, (kpi) =>
              asThousands(kpi.gmv_per_day)
            ),
            formatKpiRow('Average Basket Size (Excl VAT)', monthly_kpi, (kpi) =>
              kpi.sector_ave_total.toFixed(2)
            ),
            formatKpiRow('No of Transactions', monthly_kpi, (kpi) =>
              asThousands(kpi.count)
            ),
            formatKpiRow('Avg No of Transactions per day', monthly_kpi, (kpi) =>
              kpi.avg_transactions_per_day.toFixed(0)
            ),
            emptyKpiRow(),
            emptyKpiRow(),
            kpiSectionRow('Per-transaction Analysis'),
            kpiSubsectionRow('Fulfillment Type'),
            formatKpiRow('Click & Collect', monthly_kpi, (kpi) =>
              asThousands(kpi.pickup)
            ),
            formatKpiRow('Slerp Courier Partner', monthly_kpi, (kpi) =>
              asThousands(kpi.last_mile)
            ),
            formatKpiRow('Self Delivery', monthly_kpi, (kpi) =>
              asThousands(kpi.self_fulfilled)
            ),
            formatKpiRow('Order at Table', monthly_kpi, (kpi) =>
              asThousands(kpi.table_order || 0)
            ),
            formatKpiRow(
              'Total',
              monthly_kpi,
              (kpi) => asThousands(kpi.fulfill_type_total),
              {
                total: true
              }
            ),
            formatKpiRow('Average Delivery Income', monthly_kpi, (kpi) =>
              kpi.ave_del.toFixed(2)
            ),
            emptyKpiRow()
          )

          rows.push(
            kpiSubsectionRow('Order Type'),
            formatKpiRow('ASAP', monthly_kpi, (kpi) => asThousands(kpi.asap)),
            formatKpiRow('Scheduled Same Day', monthly_kpi, (kpi) =>
              asThousands(kpi.ssd)
            ),
            formatKpiRow('Preorder', monthly_kpi, (kpi) =>
              asThousands(kpi.pre)
            ),
            formatKpiRow('Order at Table', monthly_kpi, (kpi) =>
              asThousands(kpi.table_order || 0)
            ),
            formatKpiRow(
              'Total',
              monthly_kpi,
              (kpi) => asThousands(kpi.order_type_total),
              {
                total: true
              }
            ),
            emptyKpiRow()
          )

          rows.push(
            kpiSectionRow('Platform'),

            kpiSubsectionRow('Transactions by Platform'),
            formatKpiRow('Web - Mobile', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_count_web_mobile)
            ),
            formatKpiRow('Web - Desktop', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_count_web_desktop)
            ),
            formatKpiRow('IOS - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_count_ios_app)
            ),
            formatKpiRow('Android - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_count_android_app)
            ),
            formatKpiRow('Unknown', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_count_unknown)
            ),
            formatKpiRow(
              'Total',
              monthly_kpi,
              (kpi) => asThousands(kpi.platform_count_total),
              {
                total: true
              }
            ),

            kpiSubsectionRow('GMV by Platform'),
            formatKpiRow('Web - Mobile', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_gmv_web_mobile)
            ),
            formatKpiRow('Web - Desktop', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_gmv_web_desktop)
            ),
            formatKpiRow('IOS - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_gmv_ios_app)
            ),
            formatKpiRow('Android - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_gmv_android_app)
            ),
            formatKpiRow('Unknown', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_gmv_unknown)
            ),
            formatKpiRow(
              'Total',
              monthly_kpi,
              (kpi) => asThousands(kpi.platform_gmv_total),
              {
                total: true
              }
            ),

            kpiSubsectionRow('AOV by Platform'),
            formatKpiRow('Web - Mobile', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_aov_web_mobile)
            ),
            formatKpiRow('Web - Desktop', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_aov_web_desktop)
            ),
            formatKpiRow('IOS - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_aov_ios_app)
            ),
            formatKpiRow('Android - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_aov_android_app)
            ),
            formatKpiRow('Unknown', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_aov_unknown)
            ),
            formatKpiRow(
              'Total',
              monthly_kpi,
              (kpi) => asThousands(kpi.platform_aov_total),
              {
                total: true
              }
            ),

            kpiSubsectionRow('Refunds Count by Platform'),
            formatKpiRow('Web - Mobile', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_refunds_web_mobile)
            ),
            formatKpiRow('Web - Desktop', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_refunds_web_desktop)
            ),
            formatKpiRow('IOS - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_refunds_ios_app)
            ),
            formatKpiRow('Android - App', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_refunds_android_app)
            ),
            formatKpiRow('Unknown', monthly_kpi, (kpi) =>
              asThousands(kpi.platform_refunds_unknown)
            ),
            formatKpiRow(
              'Total',
              monthly_kpi,
              (kpi) => asThousands(kpi.platform_refunds_total),
              {
                total: true
              }
            )
          )

          return (
            <table className='kpi-table'>
              <tbody>
                {rows.map((row) => (
                  <tr>{row}</tr>
                ))}
              </tbody>
            </table>
          )
        }
      }}
    </Query>
  )
}

const MerchantKPI = (props) => {
  const [displayToMonth, setDisplayToMonth] = useState(
    formatMonth(startOfMonth(new Date()))
  )

  const { merchantId } = props

  return (
    <>
      <Col>
        <Query query={QUERY_AVAILABLE_KPI_MONTHS}>
          {({ data, loading }) => {
            if (loading) {
              return <Skeleton paragraph={{ rows: 1 }} active loading />
            }

            if (data && data.months) {
              const availableMonths = data.months.map((d) => d.month).reverse()

              let intervals = {}
              for (let i = 0; i < availableMonths.length; i += DISPLAY_MONTHS) {
                const slice = availableMonths.slice(i, i + DISPLAY_MONTHS)
                intervals[slice[0]] = [
                  strftime('%b %y', new Date(slice[slice.length - 1])),
                  strftime('%b %y', new Date(slice[0]))
                ]
              }

              return (
                <>
                  <IntervalPicker
                    intervals={intervals}
                    setKey={setDisplayToMonth}
                    selectedKey={displayToMonth}
                  />
                  <KPITable to={displayToMonth} merchantId={merchantId} />
                </>
              )
            }
          }}
        </Query>
      </Col>
    </>
  )
}

const IntervalPicker = ({ intervals, setKey, selectedKey }) => {
  const onSelect = ({ key }) => {
    setKey(key)
  }

  const renderInterval = (key) => {
    if (intervals[key] && intervals[key][0])
      return `${intervals[key][0]} - ${intervals[key][1]}`
    return <>Select interval </>
  }

  const menu = (
    <Menu onClick={onSelect}>
      {Object.keys(intervals).map((key) => (
        <Menu.Item key={key}>{renderInterval(key)}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu}>
      <Button>
        {renderInterval(selectedKey) || 'Select interval'}
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  )
}

export default MerchantKPI
