import { gql } from '@apollo/client'

export const TOGGLE_FOR_TESTING = gql`
  mutation enableTesting($merchantId: uuid!, $for_testing: Boolean!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _set: { for_testing: $for_testing }
    ) {
      affected_rows
      returning {
        slug
      }
    }
  }
`
