import { useEffect, useState } from 'react'
import { PageHeader, Tabs, Checkbox } from 'antd'
import { QUERY_MERCHANTS } from './MerchantQueries'
import MerchantsList from './MerchantsList'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { filter } from 'lodash'
import { useQuery } from '@apollo/client'

const { TabPane } = Tabs

const MerchantsDebuggerContainer = ({ client }) => {
  const [filteredMerchants, setFilteredMerchants] = useState([])
  const [currentFilter, setCurrentFilter] = useState('')
  const [published, setPublished] = useState(true)

  const { loading, data, networkStatus } = useQuery(QUERY_MERCHANTS, {
    variables: {
      limit: 2500,
      published
    }
  })

  useEffect(() => {
    setFilteredMerchants(data?.merchants)
  }, [networkStatus, data?.merchants])

  const onFilter = (e) => {
    setCurrentFilter(e.target.value)
    const filtered = filter(data?.merchants, (m) =>
      m.name.toLowerCase().match(e.target.value.toLowerCase())
    )
    if (filtered.length > 0) {
      setFilteredMerchants(filtered)
    }

    return true
  }

  const togglePublished = (value) => {
    setPublished(value)
  }

  return (
    <div className='merchants'>
      <>
        <PageHeader title='Partners' subTitle='Feature Debugger Tool' />
        <Input
          placeholder='Search Merchant'
          onChange={onFilter}
          suffix={<SearchOutlined />}
          value={currentFilter}
          style={{ marginBottom: '8px', maxWidth: '400px', marginRight: '8px' }}
        />
        <span style={{ marginRight: '8px' }}>Published:</span>

        <Checkbox
          onChange={(e) => togglePublished(e.target.checked)}
          defaultChecked={true}
        >
          Active
        </Checkbox>
        <Tabs
          defaultActiveKey='1'
          type='card'
          size='large'
          style={{ marginTop: 16 }}
        >
          <TabPane
            tab={
              filteredMerchants &&
              `${published ? 'Active' : 'Pending'} Partners (${
                filteredMerchants.length
              })`
            }
            key='1'
            style={{ overflowX: 'auto' }}
          >
            <MerchantsList
              merchants={filteredMerchants}
              client={client}
              loading={loading}
              key='active'
              defaultSort='seven'
            />
          </TabPane>
        </Tabs>
      </>
    </div>
  )
}

export default MerchantsDebuggerContainer
