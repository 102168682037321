import { useState } from 'react'
import { Alert, Button, Row, Col } from 'antd'

const UnifiedTool = () => {
  const [disabled, setDisabled] = useState(false)
  const [response, setResponse] = useState<string>()

  const jwt = localStorage.getItem('token')
  const MONOLITH_HOST = process.env.REACT_APP_MONOLITH_HOST

  const trigger = async () => {
    setDisabled(true)
    const fetchResponse = await fetch(
      `${MONOLITH_HOST}/__admin/monthly_reporting_mailer`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
    setResponse(JSON.stringify(fetchResponse))
  }

  return (
    <Row>
      <Col>
        <Alert
          message='Monthly Reporting Mailer'
          description='This feature takes about 30 minutes. Please do not trigger it more than once in an hour.'
          showIcon
          type='warning'
        />
        <br />
        <Button onClick={trigger} disabled={disabled}>
          Trigger{' '}
        </Button>
        {response && (
          <>
            <br />
            <br />
            {response}
          </>
        )}
      </Col>
    </Row>
  )
}

export default UnifiedTool
