import React, { useState, useEffect } from 'react'
import { Row, Col, PageHeader } from 'antd'
import GoogleMapReact from 'google-map-react'
import { useQuery } from '@apollo/client'
import { QUERY_MERCHANT_STORES } from './MerchantQueries'
import { flattenDeep, filter } from 'lodash'
import moment from 'moment-timezone'

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY
const DOMAIN_SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX

const MerchantMapContainer = ({ client }) => {
  const [activeMerchants, setActiveMerchants] = useState([])
  const [inactiveMerchants, setInactiveMerchants] = useState([])
  const [noTransactions, setNoTransactions] = useState([])
  const [lastOrder] = useState(7) // 7 days

  const { data, networkStatus } = useQuery(QUERY_MERCHANT_STORES, {
    variables: {
      sector: null,
      limit: 2500
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    const filteredActive = filter(
      data?.merchants,
      (m) => moment().diff(moment(m.stats.last_ordered_at), 'days') < lastOrder
    )
    const filteredInactive = filter(
      data?.merchants,
      (m) =>
        !m.stats.last_ordered_at ||
        moment().diff(moment(m.stats.last_ordered_at), 'days') >= lastOrder
    )

    const filteredNotransactions = filter(
      data?.merchants,
      (m) => !m.stats.last_ordered_at
    )

    setActiveMerchants(filteredActive)
    setInactiveMerchants(filteredInactive)
    setNoTransactions(filteredNotransactions)
  }, [networkStatus, data?.merchants])

  const allStores = () =>
    flattenDeep(data?.merchants.map((merchant) => merchant.stores))

  const openStores = () => allStores().filter((store) => store.is_open).length

  const loadMarkers = (map, merchants, icon) => {
    merchants &&
      merchants.forEach((merchant) => {
        merchant.stores.forEach((store) => {
          const { name } = store
          const address_cache = JSON.parse(store.address_cache)
          if (address_cache) {
            const { lat, lng, address } = address_cache

            const marker = new google.maps.Marker({
              position: { lat: parseFloat(lat), lng: parseFloat(lng) },
              title: name,
              icon: {
                url: icon
              }
            })

            const infoWindow = new google.maps.InfoWindow({
              content: `<h2 style='line-height:1;margin-bottom:8px;padding:0;'>${merchant.name}</h2>
              <a href='https://${merchant.slug}.${DOMAIN_SUFFIX}' target='_blank'>https://${merchant.slug}.${DOMAIN_SUFFIX}</a>
              <h4>${name}</h4>
              <p style='text-transform: uppercase'>${merchant.sector}</p>
              <p>${address}</p>
              <a href='/merchants/${merchant.slug}'>Show details</a>
              `
            })

            marker.addListener('click', () => {
              infoWindow.open(map, marker)
            })
            marker.setMap(map)
          }
        })
      })
  }

  const onGoogleApiLoaded = ({ map, maps }) => {
    loadMarkers(
      map,
      activeMerchants,
      'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
    )
    loadMarkers(
      map,
      inactiveMerchants,
      'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
    )
    loadMarkers(
      map,
      noTransactions,
      'https://maps.google.com/mapfiles/ms/icons/purple-dot.png'
    )
  }

  return (
    <>
      <PageHeader title='Merchant Store Map'>
        <h4>
          {`${activeMerchants?.length} total merchants. ${
            allStores()?.length
          } total stores as of ${new Date().toLocaleDateString()}. ${openStores()} currently open.`}
        </h4>
      </PageHeader>

      <Row>
        <Col span={24} style={{ height: '1000px' }}>
          {activeMerchants && activeMerchants.length > 0 && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: MAPS_API_KEY
              }}
              defaultCenter={{ lat: 51.5137137, lng: -0.1285204 }}
              defaultZoom={14}
              onGoogleApiLoaded={onGoogleApiLoaded}
              yesIWantToUseGoogleMapApiInternals
            />
          )}
        </Col>
      </Row>
    </>
  )
}

export default MerchantMapContainer
