import React from 'react'
import { Drawer } from 'antd'
import MerchantInfo from './MerchantInfo'

const MerchantDrawer = ({ merchant, onClose, visible }) => {
  return (
    <Drawer width='50%' onClose={onClose} visible={visible}>
      <MerchantInfo merchant={merchant} />
    </Drawer>
  )
}

export default MerchantDrawer
