import { gql } from '@apollo/client'

const QUERY_COMMISSION_PLANS = gql`
  query listCommissionPlans {
    commission_rates {
      id
      name
      slug
      plan_id
      delivery
      same_day_self_delivery
      preorder_delivery
      preorder_self_delivery
      nationwide_delivery
      pickup
      preorder_pickup
      table
    }
  }
`

const QUERY_COMMISSION_BY_SLUG = gql`
  query getCommissionBySlug($slug: String!) {
    commission_rates(where: { slug: { _eq: $slug } }) {
      id
      name
      slug
    }
  }
`

const CREATE_COMMISSION_PLANS = gql`
  mutation createCommissionPlan(
    $id: uuid!
    $plan_id: String!
    $slug: String!
    $name: String!
    $delivery: numeric!
    $pickup: numeric!
    $preorder_delivery: numeric!
    $nationwide_delivery: numeric!
    $table: numeric!
    $preorder_pickup: numeric!
    $same_day_self_delivery: numeric!
    $preorder_self_delivery: numeric!
  ) {
    commission_rate: insert_commission_rates_one(
      object: {
        id: $id
        inserted_at: "now()"
        updated_at: "now()"
        name: $name
        plan_id: $plan_id
        slug: $slug
        delivery: $delivery
        pickup: $pickup
        preorder_delivery: $preorder_delivery
        nationwide_delivery: $nationwide_delivery
        preorder_pickup: $preorder_pickup
        table: $table
        same_day_self_delivery: $same_day_self_delivery
        preorder_self_delivery: $preorder_self_delivery
      }
    ) {
      id
    }
  }
`

export {
  QUERY_COMMISSION_PLANS,
  CREATE_COMMISSION_PLANS,
  QUERY_COMMISSION_BY_SLUG
}
