import { Form, Input, Button, message, Space, Col, Row, Typography } from 'antd'
import { useMutation } from '@apollo/react-hooks'

import { UPDATE_SETTINGS } from '../StoreQueries'

const ComtrexForm = ({ store, client }) => {
  const onFinish = ({
    comtrexUrl,
    vendorId,
    deliverySalesId,
    deliveryPaymentId,
    pickupSalesId,
    pickupPaymentId,
    tableSalesId,
    tablePaymentId
  }) => {
    const params = {
      storeId: store.id,
      settings: {
        comtrex: {
          url: comtrexUrl,
          vendor_id: vendorId,
          delivery_sales_id: deliverySalesId,
          delivery_payment_id: deliveryPaymentId,
          pickup_sales_id: pickupSalesId,
          pickup_payment_id: pickupPaymentId,
          table_sales_id: tableSalesId,
          table_payment_id: tablePaymentId
        }
      }
    }

    message.loading('Updating settings... Please wait.')

    updateStoreSettings({
      variables: params
    })
      .then((result) => {
        message.destroy()
        message.success('Settings ', 0)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Settings failed to update ${err.message}`, 0)
      })
  }

  const [updateStoreSettings] = useMutation(UPDATE_SETTINGS, {
    fetchPolicy: 'no-cache',
    client: client
  })

  return (
    <Form
      labelCol={{ span: 8 }}
      onFinish={onFinish}
      name={store.slug}
      labelAlign='left'
      initialValues={{
        vendorId: store.settings?.comtrex?.vendor_id,
        comtrexUrl: store.settings?.comtrex?.url,
        deliverySalesId: store.settings?.comtrex?.delivery_sales_id,
        deliveryPaymentId: store.settings?.comtrex?.delivery_payment_id,
        pickupSalesId: store.settings?.comtrex?.pickup_sales_id,
        pickupPaymentId: store.settings?.comtrex?.pickup_payment_id,
        tableSalesId: store.settings?.comtrex?.table_sales_id,
        tablePaymentId: store.settings?.comtrex?.table_payment_id
      }}
    >
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item label='Vendor ID' name='vendorId'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Comtrex URL' name='comtrexUrl'>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={5}>Delivery</Typography.Title>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item label='Sales ID' name='deliverySalesId'>
            <Input size='small' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Payment ID' name='deliveryPaymentId'>
            <Input size='small' />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={5}>Pickup</Typography.Title>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item label='Sales ID' name='pickupSalesId'>
            <Input size='small' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Payment ID' name='pickupPaymentId'>
            <Input size='small' />
          </Form.Item>
        </Col>
      </Row>
      <Typography.Title level={5}>Order at table</Typography.Title>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item label='Sales ID' name='tableSalesId'>
            <Input size='small' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Payment ID' name='tablePaymentId'>
            <Input size='small' />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
          {store.settings?.comtrex?.vendor_id && (
            <a
              href={`${process.env.REACT_APP_COMTREX_HOST}/comtrex/preview/${store.id}`}
              target='_blank'
              rel='noreferrer'
            >
              Preview Menu
            </a>
          )}
        </Space>
      </Form.Item>
    </Form>
  )
}

export default ComtrexForm
