import { gql } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { Form, PageHeader, Switch, message } from 'antd'

const UPDATE_SETTING = gql`
  mutation updateIntegrations($merchantId: uuid!, $setting: jsonb!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _append: { setting: $setting }
    ) {
      affected_rows
      returning {
        setting
      }
    }
  }
`

const FranchiseForm = (props) => {
  const { merchant, client } = props

  const [updateSetting] = useMutation(UPDATE_SETTING, {
    client: client
  })

  const handleToggle = (newValue: boolean) => {
    message.loading('Toggling Franchise Payments... Please wait.')

    updateSetting({
      variables: {
        merchantId: merchant.id,
        setting: {
          franchise_enabled: newValue
        }
      }
    })
      .then((result) => {
        const successMessage = newValue
          ? 'Franchise Payments enabled'
          : 'Franchise Payments disabled'
        message.destroy()
        message.success(successMessage, 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  return (
    <>
      <PageHeader title='Franchise Payments' />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Enable Franchise Payments'>
          <Switch
            defaultChecked={Boolean(merchant?.setting?.franchise_enabled)}
            onChange={handleToggle}
            checkedChildren='Enabled'
            unCheckedChildren='Disabled'
          />
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default FranchiseForm
