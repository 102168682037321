const required = [{ required: true, message: 'This is a required field' }]

const validSlug = [
  () => ({
    validator: (_: any, currentValue) => {
      const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g

      if (regexExp.test(currentValue)) {
        return Promise.resolve()
      }

      return Promise.reject('The following does not have a valid slug format')
    }
  })
]

export { required, validSlug }
