import { useState } from 'react'
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { Form, PageHeader, Switch, message } from 'antd'

const UPDATE_MERCHANT_PUBLISHED_AT = gql`
  mutation updateMerchantPublishedAt(
    $merchantId: uuid!
    $publishedAt: timestamp
  ) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _set: { published_at: $publishedAt }
    ) {
      affected_rows
      returning {
        published_at
      }
    }
  }
`

const PublishForm = (props) => {
  const { merchant, client } = props
  const [toggle, setToggle] = useState<Boolean>(Boolean(merchant?.published_at))

  const [updateMerchant] = useMutation(UPDATE_MERCHANT_PUBLISHED_AT, {
    client: client
  })

  const handleToggle = () => {
    const newToggle = !toggle

    message.loading(
      `${newToggle ? 'Publishing' : 'Unpublishing'} partner... Please wait.`
    )
    setToggle(newToggle)

    updateMerchant({
      variables: {
        merchantId: merchant.id,
        publishedAt: newToggle ? 'now()' : null
      }
    })
      .then((result) => {
        message.destroy()
        message.success(
          `Partner ${newToggle ? 'published' : 'unpublished'}.`,
          1
        )
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  return (
    <>
      <PageHeader title="Publish Partner's SlerpSpace" />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Publish Partner'>
          <Switch
            defaultChecked={toggle.valueOf()}
            onChange={handleToggle}
            checkedChildren='Enabled'
            unCheckedChildren='Disabled'
          />
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default PublishForm
