import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col, Statistic, Card, Tooltip } from 'antd'
import { RiseOutlined, FallOutlined, InfoCircleFilled } from '@ant-design/icons'
import 'moment-timezone'
import { GaugeChart } from '../../Accounting/Charts'
import { DAILY_BINS, GMV_TODAY } from '../../Accounting/ChartQueries'
import moment from 'moment-timezone'
import { useQuery } from '@apollo/client'

const numberFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2
})

type GmvData = {
  gmv: number
  gtv: number
  discounts: number
  transactions: number
  partners: number
  stores: number
  day: string
  application_fee: number
}

const defaultGmvData = {
  gmv: 0,
  gtv: 0,
  discounts: 0,
  transactions: 0,
  partners: 0,
  stores: 0,
  day: '2023-02-01',
  application_fee: 0
}

const defaultStats = {
  today: 0,
  yesterday: 0,
  percent: 0,
  icon: <></>,
  tooltip: <></>
}

const statLabels = {
  gmv: 'Orders Today',
  transactions: 'Transactions',
  partners: 'Partners',
  stores: 'Locations'
}

const statTooltips = {
  gmv: 'This excludes refunded and rejected/cancelled orders.'
}

const OpsStats = ({ client }) => {
  const currentDate = moment()
  const previousDate = moment().startOf('month')
  const [startDate] = useState<moment.Moment>(previousDate)
  const [endDate] = useState<moment.Moment>(currentDate)
  const [dailyBins, setDailyBins] = useState([])
  const [, setDataSource] = useState<GmvData[]>()

  const [gmvDataToday, setGmvDataToday] = useState<[GmvData, GmvData]>([
    defaultGmvData,
    defaultGmvData
  ])

  const { data: gmv_data_today } = useQuery(GMV_TODAY, {
    variables: {
      startDate: moment().add(-1, 'day').startOf('day'),
      endDate: moment().endOf('day')
    },
    pollInterval: 600000, // every 10 minutes
    notifyOnNetworkStatusChange: true
  })

  const queryBins = async () => {
    return await client.query({
      query: DAILY_BINS,
      variables: {
        startDate,
        endDate
      },
      notifyOnNetworkStatusChange: true
    })
  }

  const loadBins = async () => {
    await queryBins().then(async ({ data }) => {
      setDailyBins(data?.daily_bins)
    })
  }

  useEffect(() => {
    loadBins()
  }, [])

  useEffect(() => {
    loadBins()
  }, [startDate, endDate])

  const loadAll = async () => {
    const data = dailyBins.map((kpi) => {
      const {
        gmv,
        gtv,
        transactions,
        discounts,
        partners,
        stores,
        day,
        application_fee
      } = kpi

      return {
        gmv: Math.floor(gmv),
        gtv: Math.floor(gtv),
        transactions,
        discounts,
        partners,
        stores,
        day: moment(day).format('DD-MM-YY'),
        application_fee,
        avg_basket_size: gmv / transactions
      }
    })

    setDataSource(data)
  }

  useEffect(() => {
    loadAll()
  }, [dailyBins])

  useEffect(() => {
    if (gmv_data_today) {
      const { gmv_today } = gmv_data_today
      if (gmv_today && gmv_today[0] && gmv_today[1]) {
        setGmvDataToday([gmv_today[0], gmv_today[1]])
      } else {
        setGmvDataToday([defaultGmvData, defaultGmvData])
      }
    } else {
      setGmvDataToday([defaultGmvData, defaultGmvData])
    }
  }, [gmv_data_today, gmv_data_today?.gmv_today])

  const getStat = useCallback(
    (key: string) => {
      if (gmvDataToday[0] && gmvDataToday[1]) {
        return {
          today: gmvDataToday[0][key],
          yesterday: gmvDataToday[1][key],
          percent:
            gmvDataToday[0][key] > 0
              ? gmvDataToday[0][key] / gmvDataToday[1][key]
              : 0,
          icon:
            gmvDataToday[0][key] > gmvDataToday[1][key] ? (
              <RiseOutlined style={{ color: 'green', fontSize: 14 }} />
            ) : (
              <FallOutlined style={{ color: 'red', fontSize: 14 }} />
            ),
          tooltip: <></>
        }
      } else {
        return defaultStats
      }
    },
    [gmvDataToday[0].gmv, gmvDataToday[1].gmv]
  )

  return (
    <Row gutter={16}>
      {['gmv', 'transactions', 'partners', 'stores'].map((key) => {
        const stat = getStat(key)
        return (
          <Col xl={6} lg={12} md={12} xs={24}>
            <Card
              style={{
                backgroundColor: 'white',
                marginBottom: '8px'
              }}
            >
              <Row gutter={16} align='bottom'>
                <Col xl={6}>
                  <GaugeChart percent={stat.percent} />
                </Col>
                <Col xl={16}>
                  <Statistic
                    title={
                      <>
                        {statTooltips[key] && (
                          <Tooltip title={statTooltips[key]}>
                            <InfoCircleFilled style={{ marginRight: '4px' }} />
                          </Tooltip>
                        )}
                        {statLabels[key]} {stat.icon}
                      </>
                    }
                    value={
                      key === 'gmv'
                        ? numberFormatter.format(stat.today)
                        : stat.today
                    }
                    suffix={`Yesterday: ${
                      key === 'gmv'
                        ? numberFormatter.format(stat.yesterday)
                        : stat.yesterday
                    } `}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        )
      })}
    </Row>
  )
}

export default OpsStats
