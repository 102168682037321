import { useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import client from '../../client'
import { uniq } from 'lodash'
import { PageHeader, Divider, Row, Col, DatePicker } from 'antd'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import 'react-pivottable/pivottable.css'
import moment from 'moment-timezone'
import './pivottable.less'
import { SECTORS, ICPS } from '../Merchants/MerchantSettings'

const { RangePicker } = DatePicker

const MONTHLY_BINS = gql`
  query MonthlyBins($startDate: date!, $endDate: date!) {
    monthly_bins(where: { month: { _gte: $startDate, _lte: $endDate } }) {
      merchant_id
      month
      components
      merchant {
        id
        name
        slug
        sector
        icp
        published_at
      }
    }
  }
`

const AccountingSummaries = () => {
  const [monthlyBins, setMonthlyBins] = useState([])
  const [months, setMonths] = useState<string[]>([])
  const [dataSource, setDataSource] = useState<object[]>()

  const currentDate = new Date()
  const previousYear = new Date().setFullYear(currentDate.getFullYear() - 1)

  const [startDate, setStartDate] = useState<Date>(new Date(previousYear))
  const [endDate, setEndDate] = useState<Date>(currentDate)

  const queryBins = async () => {
    return await client.query({
      query: MONTHLY_BINS,
      variables: {
        startDate,
        endDate
      },
      notifyOnNetworkStatusChange: true
    })
  }

  const loadBins = async () => {
    await queryBins().then(async ({ data }) => {
      setMonthlyBins(data?.monthly_bins)
      setMonths(uniq(monthlyBins.map((bin) => bin.month)).sort())
    })
  }

  const handleRangePicker = (dates) => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  useEffect(() => {
    loadBins()
  }, [startDate, endDate])

  const loadAll = async () => {
    const data = monthlyBins.map((kpi) => {
      const { merchant, month, components } = kpi
      const currentMonth = moment(month).isSame(moment(), 'month')
      const totalDayCount = currentMonth
        ? moment(new Date()).date()
        : moment(month).daysInMonth()

      const {
        gmv,
        gtv,
        count,
        stores,
        courier_fee,
        slerp_fee,
        application_fee,
        asap,
        ssd,
        pre,
        table_order,
        platform_aov_web_mobile,
        platform_aov_web_desktop,
        platform_aov_ios_app,
        platform_aov_android_app
      } = components

      return {
        GMV: Math.floor(gmv),
        GTV: Math.floor(gtv),
        'Income - Commissions': Math.floor(slerp_fee),
        'Income - Slerp Courier Partner': Math.floor(courier_fee),
        'Income - Slerp Pay': Math.floor(application_fee),
        'Income - All': Math.floor(
          Number(slerp_fee) + Number(courier_fee) + Number(application_fee)
        ),
        'No of Transactions': count,
        'No of Outlets': stores,
        'Avg GMV per day': gmv / totalDayCount,
        'Avg Basket Size': gmv / count / 1.2,
        'Type - ASAP': asap - table_order,
        'Type - Schedule Same Day': ssd,
        'Type - Preorder': pre,
        'Type - Order at Table': table_order,
        'Platform - Web': platform_aov_web_desktop + platform_aov_web_mobile,
        'Platform - Mobile': platform_aov_android_app + platform_aov_ios_app,
        Sector: SECTORS[merchant?.sector],
        ICP: ICPS[merchant?.icp],
        Partner: merchant?.name.substr(0, 50),
        Month: kpi?.month
      }
    })

    setDataSource(data)
  }

  useEffect(() => {
    loadAll()
  }, [monthlyBins, months])

  const [pivot, setPivot] = useState({})

  return (
    <>
      <Row align='middle' gutter={[8, 8]}>
        <Col>
          <PageHeader title='Accounting Summaries' />
        </Col>
        <Col flex='auto'>
          Filter by date range:{' '}
          <RangePicker picker='month' onChange={handleRangePicker} />
        </Col>
      </Row>
      <Divider />
      {dataSource && (
        <PivotTableUI
          data={dataSource}
          onChange={(s) => {
            setPivot(s)
          }}
          hiddenFromDragDrop={[
            'Income - Commissions',
            'Income - Slerp Pay',
            'Income - Slerp Courier Partner',
            'Income - All',
            'GMV',
            'GTV',
            'Avg Basket Size',
            'Avg GMV per day',
            'No of Transactions',
            'Type - ASAP',
            'Type - Schedule Same Day',
            'Type - Preorder',
            'Type - Order at Table',
            'Platform - Web',
            'Platform - Mobile'
          ]}
          rows={['Partner']}
          cols={['Month']}
          vals={['GMV']}
          rendererName='Table'
          aggregatorName='Integer Sum'
          rowOrder='value_z_to_a'
          {...pivot}
        />
      )}
    </>
  )
}

export default AccountingSummaries
