import { Query } from '@apollo/client/react/components'
import { Skeleton } from 'antd'
import { QUERY_MERCHANT } from './MerchantQueries'

import MerchantInfo from './MerchantInfo'

interface MerchantContainerProps {
  client: any
  slug: string
  tab?: string
}

const MerchantContainer = ({ client, slug, tab }: MerchantContainerProps) => {
  return (
    <>
      <Query query={QUERY_MERCHANT} variables={{ slug }} fetchPolicy='no-cache'>
        {({ data, loading, refetch }) => {
          if (loading) {
            return <Skeleton active />
          }
          if (data && data.merchants) {
            return (
              <MerchantInfo
                merchant={data.merchants[0]}
                client={client}
                refetch={refetch}
                tab={tab}
              />
            )
          }
          return null
        }}
      </Query>
    </>
  )
}

export default MerchantContainer
