import ReactJSON from 'react-json-view'

const JSONView = ({ src }) => {
  return (
    <ReactJSON
      theme='monokai'
      src={src}
      collapsed={1}
      displayDataTypes={false}
    />
  )
}

export default JSONView
