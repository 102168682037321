import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Form, message, PageHeader, Switch, Alert } from 'antd'
import { TOGGLE_FOR_TESTING } from './SettingQueries'

const TesterForm = (props) => {
  const { merchant } = props

  const [loadingState, setLoadingState] = useState(false)

  const [toggleForTesting] = useMutation(TOGGLE_FOR_TESTING, {
    client: props.client
  })

  const setHosting = (value, key) => {
    const params = {
      variables: {
        for_testing: value,
        merchantId: merchant.id
      }
    }

    setLoadingState(true)

    toggleForTesting(params)
      .then(() => {
        message.destroy()
        message.success('Changed modes.')
      })
      .catch(() => {
        message.destroy()
        message.error('Failed to change mode.')
      })
      .finally(() => {
        setLoadingState(false)
      })
  }

  return (
    <>
      <PageHeader title='Tester mode' />
      <Alert
        message='Be warned!'
        description={
          <p>
            Do not turn this ON unless this SlerpSpace is for production testing
          </p>
        }
        type='warning'
        showIcon
        style={{ marginBottom: '16px' }}
      />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Tester mode:'>
          <Switch
            defaultChecked={merchant.for_testing}
            onChange={(value) => setHosting(value, 'hosts_own_preorders')}
            loading={loadingState}
            checkedChildren='Test mode ON'
            unCheckedChildren='Test mode OFF'
          />
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default TesterForm
