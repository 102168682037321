import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from '@apollo/client'
import { Form, message, PageHeader, Input, Button } from 'antd'
// Note: Need to port this to antd 4 for full function support
const DeliverectForm = (props) => {
  const UPDATE_DELIVERECT_ID = gql`
    mutation updateDeliverectChannelId($merchantId: uuid!, $setting: jsonb!) {
      update_merchants(
        where: { id: { _eq: $merchantId } }
        _append: { setting: $setting }
      ) {
        affected_rows
        returning {
          setting
        }
      }
    }
  `

  const [updateValues] = useMutation(UPDATE_DELIVERECT_ID, {
    client: props.client
  })

  const [deliverectId, setDeliverectId] = useState(
    props.merchant.setting.deliverect_id
  )

  const submit = (e) => {
    e.preventDefault()
    message.loading('Updating Deliverect channel id... Please wait.')
    updateValues({
      variables: {
        merchantId: props.merchant.id,
        setting: { deliverect_id: deliverectId }
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Deliverect channel id updated', 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  return (
    <>
      <PageHeader title='Deliverect' />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Account ID'>
          <Input
            name='deliverect_id'
            defaultValue={props.merchant.setting.deliverect_id}
            onChange={(e) => {
              setDeliverectId(e.target.value)
            }}
          />

          <Button onClick={submit}>Save</Button>
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default DeliverectForm
