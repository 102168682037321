import { useState } from 'react'
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { Form, PageHeader, Switch, message } from 'antd'

const UPDATE_CRM = gql`
  mutation updateIntegrations($merchantId: uuid!, $setting: jsonb!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _append: { integration_settings: $setting }
    ) {
      affected_rows
      returning {
        integration_settings
      }
    }
  }
`

const CORRECT_INTEGRATION_SETTINGS = gql`
  mutation updateIntegrations($merchantId: uuid!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _set: { integration_settings: {} }
    ) {
      affected_rows
      returning {
        integration_settings
      }
    }
  }
`

const CRMForm = (props) => {
  const { merchant, client } = props

  const [crmEnabled, setCrmEnabled] = useState(
    merchant.integration_settings?.crm?.enabled || false
  )

  const [correctIntegrationSettings] = useMutation(
    CORRECT_INTEGRATION_SETTINGS,
    {
      client: client
    }
  )

  if (!merchant?.integration_settings) {
    correctIntegrationSettings({
      variables: {
        merchantId: merchant.id
      }
    })
  }

  const [updateCrm] = useMutation(UPDATE_CRM, {
    client: client
  })

  const handleToggle = () => {
    message.loading('Toggling Slerp CRM... Please wait.')

    const newValue = !crmEnabled
    setCrmEnabled(newValue)
    updateCrm({
      variables: {
        merchantId: merchant.id,
        setting: {
          crm: {
            enabled: newValue
          }
        }
      }
    })
      .then((result) => {
        const flashMessage = newValue
          ? 'Slerp CRM enabled!'
          : 'Slerp CRM disabled!'

        message.destroy()
        message.success(flashMessage, 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  return (
    <>
      <PageHeader title='Slerp CRM' />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Enable Slerp CRM'>
          <Switch
            defaultChecked={Boolean(
              merchant?.integration_settings?.crm?.enabled
            )}
            onChange={handleToggle}
            checkedChildren='Enabled'
            unCheckedChildren='Disabled'
          />
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default CRMForm
