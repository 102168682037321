import React from 'react'
import { Table, Tag } from 'antd'
import moment from 'moment-timezone'
import Moment from 'react-moment'
import {
  OrderStatusTag,
  DeliveryStatusTag,
  PushStatusTag
} from './OrderStatuses'
import { capitalize } from './../Stats/utils'
import { useMediaQuery } from 'react-responsive'
import OrderLink from './OrderLink'
import { Link } from 'react-router-dom'

const OrdersList = ({ orders, showDrawer }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const columns = [
    {
      title: 'Transaction Ref',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (text, record) => (
        <OrderLink
          text={text}
          onClick={() => {
            showDrawer && showDrawer(record)
            return false
          }}
        />
      )
    },
    {
      title: 'Partner',
      dataIndex: ['merchant', 'name'],
      key: 'merchant.name',
      render: (text, record) => (
        <Link to={`/merchants/${record.merchant.slug}`}>{text}</Link>
      )
    },
    {
      title: 'Store',
      dataIndex: ['store', 'name'],
      key: 'store.name'
    },
    {
      title: 'Type',
      dataIndex: 'fulfillment_type',
      key: 'fulfillment_type'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: OrderStatusTag
    },
    {
      title: 'Delivery Status',
      dataIndex: ['delivery', 'delivery_status'],
      key: 'delivery.delivery_status',
      render: DeliveryStatusTag
    },
    {
      title: 'POS Status',
      dataIndex: ['order', 'metadata', 'deliverect_order_id'],
      key: 'order.push_status',
      render: (text, record) => <PushStatusTag record={record} /> // Ant and React seems to be acting up with too much useEffect() calls
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => {
        return <span>£{text}</span>
      }
    },
    {
      title: 'Expected At',
      dataIndex: 'fulfillment_date',
      key: 'fulfillment_date',
      render: (text, record) => {
        return (
          (record.fulfillment_date && (
            <>
              <Moment format='DD/MM/YY'>{text}</Moment>{' '}
              {record.fulfillment_time_range && (
                <> ({record.fulfillment_time_range})</>
              )}
            </>
          )) || <Tag color='red'>ASAP</Tag>
        )
      }
    },
    {
      title: 'Ordered At',
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      render: (text, record) => {
        const zone = moment(record.inserted_at).zoneAbbr()
        return `${moment(record.inserted_at)
          .add(moment(record.inserted_at).utcOffset(), 'minutes')
          .format(`DD/MM/YYYY HH:mm`)} ${zone}`
      }
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, record) => {
        if (!record.create_device_category || !record.create_platform) {
          return 'Unknown'
        } else {
          return `${capitalize(record.create_platform)} - ${capitalize(
            record.create_device_category
          )}`
        }
      }
    }
  ]

  return (
    <Table
      dataSource={orders}
      columns={columns}
      pagination={{ defaultPageSize: 50 }}
      size={isTabletOrMobileDevice ? 'small' : 'middle'}
      tableLayout='auto'
    />
  )
}

export default OrdersList
