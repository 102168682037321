import { useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import client from '../../client'
import { uniq } from 'lodash'
import { Divider, PageHeader } from 'antd'
import { Spin } from 'antd'
import moment from 'moment-timezone'

type MonthlyBin = {
  merchant_id: string
  month: string
  components: {
    gmv: number
    gtv: number
    stores: number
    slerp_fee: number
    courier_fee: number
    application_fee: number
  }
  merchant: {
    slug: string
    icp: string
    sector: string
  }
  age?: number
  startMonth?: string
}

const MONTHLY_BINS = gql`
  query MonthlyBins($startDate: date!, $endDate: date!) {
    monthly_bins(
      where: { month: { _gte: $startDate, _lte: $endDate } }
      order_by: { merchant_id: asc, month: asc }
    ) {
      merchant_id
      merchant {
        slug
        icp
        sector
      }
      month
      components
    }
  }
`

export function generateMonthArray(startDate, endDate) {
  const months = []
  let currentDate = moment(startDate).startOf('month')
  const lastDate = moment(endDate).startOf('month')

  while (currentDate.isSameOrBefore(lastDate)) {
    months.push(currentDate.format('YYYY-MM-01'))
    currentDate.add(1, 'month')
  }

  return months
}

const CohortAnalysisTable = () => {
  const [monthlyBins, setMonthlyBins] = useState([])
  const [months, setMonths] = useState<string[]>([])
  const [dataSource, setDataSource] = useState<any[]>([])

  const queryBins = async () => {
    return await client.query<{ monthly_bins: MonthlyBin[] }>({
      query: MONTHLY_BINS,
      variables: {
        startDate: '2019-07-01',
        endDate: moment().startOf('month').format('YYYY-MM-DD')
      },
      notifyOnNetworkStatusChange: true
    })
  }

  const loadBins = async () => {
    await queryBins().then(async ({ data }) => {
      setMonthlyBins(data?.monthly_bins)
      setMonths(uniq(monthlyBins.map((bin) => bin.month)).sort())
    })
  }

  useEffect(() => {
    loadBins()
  }, [])

  const defaultBinData = {
    components: {
      gmv: 0,
      gtv: 0,
      stores: 0,
      slerp_fee: 0,
      courier_fee: 0,
      application_fee: 0
    }
  }

  const merchantCohort = (merchantId: string): MonthlyBin[] => {
    const bins = monthlyBins.filter((b) => b.merchant_id === merchantId)

    const pickMonth = (month) => {
      return bins.find(
        (bin) => bin.merchant_id === merchantId && bin.month === month
      )
    }

    if (bins.length > 0) {
      const startMonth = bins[0].month
      const endMonth = bins[bins.length - 1].month
      const difference = moment(endMonth).diff(moment(startMonth), 'months')

      return generateMonthArray(startMonth, endMonth).map((month, index) => {
        const binMonth = pickMonth(month)
        if (binMonth) {
          return {
            ...binMonth,
            startMonth, // Always set month as the start month as the anchor for the pivot table
            month,
            age: index,
            totalAge: difference
          }
        } else {
          return {
            ...defaultBinData,
            merchant_id: merchantId,
            merchant: bins[0].merchant,
            startMonth, // Always set month as the start month as the anchor for the pivot table
            month,
            age: index,
            totalAge: difference
          }
        }
      })
    }
  }

  const loadMerchants = () => {
    const uniqMerchantIds = Array.from(
      new Set(monthlyBins.map((bin) => bin.merchant_id))
    )
    return uniqMerchantIds.flatMap((merchantId) => merchantCohort(merchantId))
  }

  const loadAll = async (merchantBins: MonthlyBin[]) => {
    const data = merchantBins.map((kpi) => {
      const {
        gmv,
        gtv,
        stores,
        slerp_fee,
        courier_fee,
        application_fee
      } = kpi.components

      return {
        month: kpi.month,
        startMonth: kpi.startMonth,
        slug: kpi.merchant.slug,
        icp: kpi.merchant.icp,
        sector: kpi.merchant.sector,
        locations: stores,
        income: Math.floor(
          Number(slerp_fee) + Number(courier_fee) + Number(application_fee)
        ),
        gmv,
        gtv,
        age: kpi.age
      }
    })

    setDataSource(data)
  }

  useEffect(() => {
    loadAll(loadMerchants())
  }, [monthlyBins, months])

  return (
    <>
      <PageHeader
        title='Cohort Analysis'
        subTitle={
          <>
            {dataSource.length === 0 && (
              <>
                <Spin />
                Please wait. This will take a while.
              </>
            )}
            Copy paste the table below to Excel for custom data/pivot
            transformations.
          </>
        }
      />
      <Divider />

      <table>
        <tr>
          <td>startMonth</td>
          <td>month</td>
          <td>slug</td>
          <td>age</td>
          <td>gmv</td>
          <td>gtv</td>
          <td>locations</td>
          <td>income</td>
          <td>icp</td>
          <td>sector</td>
        </tr>
        {dataSource &&
          dataSource.map((data) => {
            return (
              <tr>
                <td>{data.startMonth}</td>
                <td>{data.month}</td>
                <td>{data.slug}</td>
                <td>{data.age}</td>
                <td>{data.gmv}</td>
                <td>{data.gtv}</td>
                <td>{data.locations}</td>
                <td>{data.income}</td>
                <td>{data.icp}</td>
                <td>{data.sector}</td>
              </tr>
            )
          })}
      </table>
    </>
  )
}

export default CohortAnalysisTable
