import React, { useEffect, useState, useRef } from 'react'
import { Button, Divider, message, notification, Tooltip } from 'antd'
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import converter from 'csvtojson'
import { v4 as uuidv4 } from 'uuid'
import {
  ARCHIVE_PRICE_MATRIX,
  ASSIGN_PRICE_MATRIX_TO_STORE,
  CREATE_PRICE_MATRIX,
  UPDATE_PRICE_MATRIX_BY_ID,
  REMOVE_PRICE_MATRIX_FROM_STORE,
  RENAME_PRICE_MATRIX
} from './MerchantQueries'
import { useMutation } from '@apollo/react-hooks'
import RateCardForm from './RateCards/Form'
import RateCardDetail from './RateCards/Detail'
import { ReadRateCardImportFile, ValidateRateCardPayload } from './utils'
import './CustomDeliveryPricing.css'

interface MerchantStore {
  id: string
  name: string
}

interface PriceMatrix {
  id: string
  name: string
  global: boolean
  data: {
    car: { [key: number]: string }
    bike: { [key: number]: string }
    motorbike: { [key: number]: string }
  }
  stores: Array<{ [key: string]: string }>
}

const CustomDeliveryPricing = ({
  priceMatrices,
  id: merchantId,
  client,
  stores,
  refetch
}) => {
  const uploadButtonRef = useRef(null)
  const [updatePriceMatrix, { loading: updatingRateCard }] = useMutation(
    UPDATE_PRICE_MATRIX_BY_ID,
    {
      fetchPolicy: 'no-cache',
      client: client
    }
  )

  const [createPriceMatrix] = useMutation(CREATE_PRICE_MATRIX, {
    fetchPolicy: 'no-cache',
    client: client
  })

  const [archivePriceMatrix, { loading: archivingRateCard }] = useMutation(
    ARCHIVE_PRICE_MATRIX,
    {
      fetchPolicy: 'no-cache',
      client: client
    }
  )

  const [
    assignPriceMatrixToStore,
    { loading: assigningRateCardToStore }
  ] = useMutation(ASSIGN_PRICE_MATRIX_TO_STORE, {
    fetchPolicy: 'no-cache',
    client: client
  })

  const [
    removePriceMatrixFromStore,
    { loading: removingRateCardFromStore }
  ] = useMutation(REMOVE_PRICE_MATRIX_FROM_STORE, {
    fetchPolicy: 'no-cache',
    client: client
  })

  const [renamePriceMatrix, { loading: renamingPriceMatrix }] = useMutation(
    RENAME_PRICE_MATRIX,
    {
      fetchPolicy: 'no-cache',
      client: client
    }
  )

  const [globalPriceMatrix, setGlobalPriceMatrix] = useState<PriceMatrix>(null)

  const [storeLevelMatrices, setStoreLevelMatrices] = useState<PriceMatrix[]>(
    []
  )

  const [merchantStores] = useState<MerchantStore[]>(
    stores.map((store) => ({ id: store.id, name: store.name }))
  )

  const [availableStores, setAvailableStores] = useState<string[]>([])

  const getAvailableStores = () => {
    const allStoreIds = merchantStores.map((store) => store.id)
    const priceMatricesStoreIds = priceMatrices.flatMap(({ stores }) =>
      stores.map((store) => store.id)
    )
    return allStoreIds.filter(
      (storeId) => !priceMatricesStoreIds.includes(storeId)
    )
  }

  const validatePriceMatrices = () => {
    const globalPriceMatrices = priceMatrices.filter(
      (priceMatrix) => priceMatrix.stores.length === 0
    )
    if (globalPriceMatrices.length > 1) {
      notification.warning({
        message: 'Price Matrices',
        duration: 10,
        description: `There are ${globalPriceMatrices.length} price matrices currently set to global. Note that only one global price matrix should be active`
      })
    }
  }

  useEffect(() => {
    setAvailableStores(getAvailableStores())
    validatePriceMatrices()
    setStoreLevelMatrices(
      priceMatrices.filter((matrix: PriceMatrix) => matrix.stores.length !== 0)
    )
    setGlobalPriceMatrix(
      priceMatrices.filter((matrix: PriceMatrix) => matrix.global)[0]
    )
  }, [priceMatrices])

  const addOrUpdateRateCard = async (
    event: React.ChangeEvent<HTMLInputElement>,
    rateCardId: string
  ) => {
    const file = event.target.files[0]
    if (!file) return
    const rawCsvRows = await ReadRateCardImportFile(file)
    const csvRows = await converter({ flatKeys: true }).fromString(
      rawCsvRows as string
    )
    const payload = {
      bike: csvRows[0],
      motorbike: csvRows[1],
      car: csvRows[2]
    }

    // validate payload
    const result = ValidateRateCardPayload(payload)
    if (!result.valid) {
      notification.warning({
        message: 'Price Matrices',
        duration: 10,
        description: result.errorMessages
      })
      return
    }

    if (rateCardId)
      return updatePriceMatrix({
        variables: { merchantId, data: payload, rateCardId }
      }).then((result) => refetch())

    const newMatrix = {
      merchant_id: merchantId,
      id: uuidv4(),
      inserted_at: new Date(),
      updated_at: new Date(),
      data: payload,
      units: 'km',
      global: true
    }

    createPriceMatrix({ variables: { object: newMatrix } }).then((result) =>
      refetch()
    )
  }

  const archivePriceMatrixHandler = (id: string) => {
    archivePriceMatrix({
      variables: {
        id,
        archived_at: new Date()
      }
    })
      .then((result) => refetch())
      .catch((error: Error) => {
        message.destroy()
        message.error(
          `Unable to archive price matrix due to ${error.message}`,
          3
        )
      })
  }

  const assignPriceMatrixToStoreHandler = (
    storeId: string,
    priceMatrixId: string
  ) => {
    assignPriceMatrixToStore({
      variables: {
        storeIds: [storeId],
        priceMatrixId
      }
    })
      .then((result) => refetch())
      .catch((error: Error) => {
        message.destroy()
        message.error(
          `Unable to assign price matrix to store due to ${error.message}`,
          3
        )
      })
  }

  const removePriceMatrixFromStoreHandler = (
    storeId: string,
    priceMatrixId: string
  ) => {
    removePriceMatrixFromStore({
      variables: {
        storeIds: [storeId]
      }
    })
      .then((result) => refetch())
      .catch((error: Error) => {
        message.destroy()
        message.error(
          `Unable to remove price matrix from store due to ${error.message}`,
          3
        )
      })
  }

  const renamePriceMatrixHandler = (
    priceMatrixId: string,
    name: string = ''
  ) => {
    renamePriceMatrix({
      variables: {
        priceMatrixId,
        name
      }
    })
      .then((result) => refetch())
      .catch((error: Error) => {
        message.destroy()
        message.error(`Unable to set price matrix name to ${error.message}`, 3)
      })
  }

  const isRateCardProcessing =
    archivingRateCard ||
    updatingRateCard ||
    assigningRateCardToStore ||
    removingRateCardFromStore ||
    renamingPriceMatrix

  const TooltipDetail = () => (
    <span>
      <a
        href='https://www.notion.so/slerp/Rate-cards-5f62a9672de84ca588b461de96b44488'
        target='_blank'
        rel='noopener noreferrer'
        style={{ color: '#fff', textDecoration: 'underline' }}
      >
        Rate card wiki
      </a>
    </span>
  )

  return (
    <>
      <div className='rate-cards'>
        <Divider orientation='left' dashed className='label'>
          <span className='type-caption'>Global rate card</span>
          <Tooltip placement='topLeft' title={TooltipDetail} color='#4a4a68'>
            <QuestionCircleOutlined />
          </Tooltip>
        </Divider>
        {globalPriceMatrix && (
          <RateCardDetail
            loading={isRateCardProcessing}
            availableStores={availableStores}
            merchantStores={merchantStores}
            priceMatrix={globalPriceMatrix.data}
            updateRateCard={(e: React.ChangeEvent<HTMLInputElement>, id) =>
              addOrUpdateRateCard(e, globalPriceMatrix.id)
            }
            archiveRateCard={archivePriceMatrixHandler}
            assignRateCardToStore={assignPriceMatrixToStoreHandler}
            removeRateCardFromStore={removePriceMatrixFromStoreHandler}
            renameRateCard={renamePriceMatrixHandler}
            id={globalPriceMatrix.id}
            name={globalPriceMatrix.name || 'Global Rate Card'}
            isGlobal={globalPriceMatrix.global}
          />
        )}
        {!globalPriceMatrix && (
          <div className='add-rate-card'>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => uploadButtonRef.current.click()}
            >
              UPLOAD RATE CARD
            </Button>
            <input
              ref={uploadButtonRef}
              hidden
              type='file'
              accept='.csv'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                addOrUpdateRateCard(e, '')
              }
            />
          </div>
        )}
      </div>

      <div className='rate-cards'>
        <Divider orientation='left' dashed className='label'>
          <span className='type-caption'>Location rate cards</span>
          <Tooltip placement='topLeft' title={TooltipDetail} color='#4a4a68'>
            <QuestionCircleOutlined />
          </Tooltip>
        </Divider>
        {storeLevelMatrices.map((storeLevelMatrix) => (
          <RateCardDetail
            key={uuidv4()}
            loading={isRateCardProcessing}
            availableStores={availableStores}
            merchantStores={merchantStores}
            priceMatrix={storeLevelMatrix.data}
            priceMatrixStores={storeLevelMatrix.stores.map((store) => store.id)}
            updateRateCard={(e: React.ChangeEvent<HTMLInputElement>, id) =>
              addOrUpdateRateCard(e, storeLevelMatrix.id)
            }
            archiveRateCard={archivePriceMatrixHandler}
            assignRateCardToStore={assignPriceMatrixToStoreHandler}
            removeRateCardFromStore={removePriceMatrixFromStoreHandler}
            renameRateCard={renamePriceMatrixHandler}
            id={storeLevelMatrix.id}
            name={storeLevelMatrix.name || 'Store Rate Card'}
            isGlobal={storeLevelMatrix.global}
          />
        ))}
        {!!storeLevelMatrices.length && <Divider />}
        <div className='add-rate-card'>
          <RateCardForm
            merchantId={merchantId}
            merchantStores={merchantStores}
            availableStores={availableStores}
            client={client}
            onSaveCallback={() => refetch()}
            key={uuidv4()}
          />
        </div>
      </div>
    </>
  )
}

export default CustomDeliveryPricing
