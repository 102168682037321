import { Form, Input, Button, message } from 'antd'
import { useMutation } from '@apollo/react-hooks'

import { UPDATE_SETTINGS } from '../StoreQueries'

const DeliverectLinkForm = ({ store, client }) => {
  const onFinish = ({ deliverectLinkId }) => {
    const params = {
      storeId: store.id,
      settings: {
        deliverect_link_id: deliverectLinkId
      }
    }

    message.loading('Updating settings... Please wait.')

    updateStoreSettings({
      variables: params
    })
      .then((result) => {
        message.destroy()
        message.success('Settings ', 0)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Settings failed to update ${err.message}`, 0)
      })
  }

  const [updateStoreSettings] = useMutation(UPDATE_SETTINGS, {
    fetchPolicy: 'no-cache',
    client: client
  })

  return (
    <Form
      labelCol={{ span: 8 }}
      onFinish={onFinish}
      name={store.slug}
      labelAlign='left'
      initialValues={{
        deliverectLinkId: store.settings?.deliverect_link_id
      }}
    >
      <Form.Item label='Link ID' name='deliverectLinkId'>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default DeliverectLinkForm
