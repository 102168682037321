import { useState } from 'react'
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { Button, Form, PageHeader, Switch, message, Input } from 'antd'
import { urlRule, requiredRule } from './Rules'

const UPDATE_MERCHANT_APP_SETTINGS = gql`
  mutation updateMerchantAppSettings($merchantId: uuid!, $enabled: Boolean) {
    update_app_settings(
      where: { merchant_id: { _eq: $merchantId } }
      _set: { enabled: $enabled }
    ) {
      affected_rows
    }
  }
`

const UPDATE_MERCHANT_MOBILE_APP_ENABLER_URL_SETTINGS = gql`
  mutation updateMerchantMobileAppEnablerUrlSettings(
    $merchantId: uuid!
    $googlePlayUrl: String!
    $appleStoreUrl: String!
  ) {
    update_app_settings(
      where: { merchant_id: { _eq: $merchantId } }
      _set: { google_play_url: $googlePlayUrl, apple_store_url: $appleStoreUrl }
    ) {
      affected_rows
    }
  }
`

const { useForm } = Form

const AppForm = (props) => {
  const [form] = useForm()
  const { merchant, client } = props
  const [showMobileAppStoreUrls, setShowMobileAppStoreUrls] = useState<boolean>(
    merchant?.app_settings[0]?.enabled
  )
  const [updateMerchantSettings, { loading }] = useMutation(
    UPDATE_MERCHANT_APP_SETTINGS,
    {
      client: client
    }
  )
  const [
    updateMerchantMobileAppEnablerUrlSettings,
    { loading: updatingMerchantMobileAppEnablerUrlSettings }
  ] = useMutation(UPDATE_MERCHANT_MOBILE_APP_ENABLER_URL_SETTINGS, {
    client: client
  })

  const formLoading = loading || updatingMerchantMobileAppEnablerUrlSettings

  const initialValues = {
    apple_store_url: merchant?.app_settings[0]?.apple_store_url,
    google_play_url: merchant?.app_settings[0]?.google_play_url,
    enabled: merchant?.app_settings[0]?.enabled
  }

  const handleToggle = (checked: boolean) => {
    message.loading(
      `${checked ? 'Enabling' : 'Disabling'} App Settings... Please wait.`
    )

    updateMerchantSettings({
      variables: {
        merchantId: merchant.id,
        enabled: checked
      }
    })
      .then(() => {
        message.destroy()
        message.success(
          `App settings has been ${checked ? 'enabled' : 'disabled'}!`,
          1
        )
        setShowMobileAppStoreUrls(checked)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }
  const submitMobileAppStoreUrlSetting = () => {
    const appleStoreUrl = form.getFieldValue('apple_store_url')
    const googlePlayUrl = form.getFieldValue('google_play_url')

    form
      .validateFields()
      .then(() => {
        updateMerchantMobileAppEnablerUrlSettings({
          variables: {
            merchantId: merchant.id,
            appleStoreUrl,
            googlePlayUrl
          }
        })
          .then(() => {
            message.destroy()
            message.success('Merchant mobile app url settings updated!', 1)
          })
          .catch((err) => {
            message.destroy()
            message.error(`Unable to update ${err.message}`, 1)
          })
      })
      .catch((error) => {
        console.error(
          'Error attempting to update mobile app url settings',
          error
        )
      })
  }

  return (
    <>
      <PageHeader title='App' />
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item name='enabled' label='App Settings' valuePropName='checked'>
          <Switch
            onChange={handleToggle}
            checkedChildren='Enabled'
            unCheckedChildren='Disabled'
            loading={formLoading}
            data-testid='app-settings-switch'
          />
        </Form.Item>
        {showMobileAppStoreUrls && (
          <>
            <Form.Item
              label='App Store:'
              name='apple_store_url'
              rules={[urlRule, requiredRule]}
            >
              <Input disabled={formLoading} />
            </Form.Item>
            <Form.Item
              label='Google Play:'
              name='google_play_url'
              rules={[urlRule, requiredRule]}
            >
              <Input disabled={formLoading} />
            </Form.Item>
            <Form.Item
              label='test'
              className='-mobile-app-store-url-save-button'
            >
              <Button
                onClick={submitMobileAppStoreUrlSetting}
                loading={formLoading}
              >
                Save
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
      <br />
      <br />
    </>
  )
}

export default AppForm
