import React from 'react'
import {
  Steps,
  Descriptions,
  Divider,
  Table,
  Tabs,
  Tag,
  Row,
  Col,
  PageHeader,
  Button
} from 'antd'
import Moment from 'react-moment'
import JSONView from '../Utils/JSONView'
import DeliveryControl from './DeliveryControl'
import { parseISO, format } from 'date-fns'
import SmsUtil from '../Messaging/SmsUtil'
import Comtrex from './Comtrex'

const { Step } = Steps
const { TabPane } = Tabs
const { Column } = Table

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY
const DOMAIN_SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX

const humanizePlatform = (str: string) => {
  return str === 'ios' ? 'iOS' : str?.charAt(0).toUpperCase() + str?.slice(1)
}

const getEventTitle = ({ data, type }) => {
  const { to } = data
  if (type === 'order_status_update') {
    const dictionary = {
      pending: 'Order was placed',
      accepted: 'Order was accepted',
      fulfilled: 'Order was confirmed fulfilled'
    }
    return dictionary[to] || `Order was set to ${to}`
  } else {
    const dictionary = {
      searching: 'Third party is booking a driver',
      picking: 'Rider is retrieving package',
      delivering: 'Rider is on their way to the customer',
      delivered: 'Items have been delivered'
    }

    return dictionary[to] || `Delivery was ${to}`
  }
}

const getEventDescription = ({ inserted_at, payload }) => {
  const actionTime = format(parseISO(inserted_at), 'dd/MM/yyyy HH:mm:ss')
  return payload.data.misc && payload.data.misc.user_agent
    ? `Done via ${payload.data.misc.user_agent} ${actionTime}`
    : `${actionTime}`
}

const GoogleMapStatic = (props) => {
  const apikey = MAPS_API_KEY
  const { geom } = props

  return (
    <img
      src={`https://maps.googleapis.com/maps/api/staticmap?center=${geom.lat},${geom.lng}&zoom=13&size=440x300&maptype=roadmap&markers=color:green%7Clabel:G%7C${geom.lat},${geom.lng}&key=${apikey}`}
      alt='Google Map'
    />
  )
}

interface OrderDetailProps {
  order: any
  client: any
  orderTrackingToken: string
}

const OrderDetails = (props: OrderDetailProps) => {
  const { order, client, orderTrackingToken } = props
  const {
    customer_details,
    customer_details: { address },
    delivery,
    payment,
    store,
    order_items,
    merchant,
    event_logs
  } = order

  const compiledAddress = address
    ? `${address.line_1} ${address.line_2} ${address.city} ${address.zip}`
    : ''

  const trackOrder = () => {
    const orderTrackingLink = `https://${merchant.slug}.${DOMAIN_SUFFIX}/order/track/${props.order.transaction_id}?token=${orderTrackingToken}`
    window.open(orderTrackingLink, '_blank')
  }

  return (
    <>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Info' key='1'>
          <Descriptions title='Order Info'>
            <Descriptions.Item label='Name'>
              {customer_details.first_name} {customer_details.last_name}
            </Descriptions.Item>
            <Descriptions.Item label='Email'>
              {customer_details.email}
            </Descriptions.Item>
            <Descriptions.Item label='Number'>
              {customer_details.contact_num}
            </Descriptions.Item>
            <Descriptions.Item label='Store'>{store.name}</Descriptions.Item>
            <Descriptions.Item label='Placed At'>
              <Moment>{order.inserted_at}</Moment>
            </Descriptions.Item>
            <Descriptions.Item label='Platform'>
              {humanizePlatform(order.create_platform)} -{' '}
              {humanizePlatform(order.create_device_category)}
            </Descriptions.Item>
            <Descriptions.Item label='Address' span={3}>
              {address && (
                <>
                  {compiledAddress}{' '}
                  <a
                    href={`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
                      compiledAddress
                    )}&key=${process.env.REACT_APP_MAPS_API_KEY}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Click here to see geocoding results
                  </a>
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label='Notes'>
              {order.additional_notes}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Button onClick={trackOrder} type='primary' htmlType='submit'>
            Track Order
          </Button>
          <Divider />
          <Table dataSource={order_items} pagination={false}>
            <Column
              title='Product'
              dataIndex='product_variant.name'
              key='product'
              render={(text: string, record: any) => {
                return (
                  <span>
                    {record.quantity} x {record.product_variant.name}
                  </span>
                )
              }}
            />
            <Column
              title='Price'
              dataIndex='amount'
              key='amount'
              render={(text, record) => {
                return <span>£{text}</span>
              }}
            />
          </Table>
        </TabPane>
        <TabPane tab='Delivery' key='2'>
          {delivery && (
            <Descriptions title='Delivery Info'>
              <Descriptions.Item label='Job ID'>
                {delivery.job_id}
              </Descriptions.Item>
              <Descriptions.Item label='Status' span={2}>
                {delivery.delivery_status}
              </Descriptions.Item>
              <Descriptions.Item label='Pickup Time'>
                <Moment>{delivery.pickup_time}</Moment>
              </Descriptions.Item>
              <Descriptions.Item label='Dropoff Time' span={2}>
                <Moment>{delivery.dropoff_time}</Moment>
              </Descriptions.Item>
              <Descriptions.Item label='Driver Name'>
                {delivery.driver
                  ? `${delivery.driver.first_name} ${delivery.driver.last_name}`
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label='Phone'>
                {delivery.driver ? delivery.driver.phone : null}
              </Descriptions.Item>
              <Descriptions.Item label='Transport Type'>
                {delivery.driver ? delivery.driver.transport_type : null}
              </Descriptions.Item>
              <Descriptions.Item label='Fee'>
                £{delivery.initial_fee}
              </Descriptions.Item>
            </Descriptions>
          )}
          <Descriptions>
            <Descriptions.Item label='ETA' span={2}>
              {order.delivery_eta} mins
            </Descriptions.Item>
            <Descriptions.Item label='Dropoff Notes' span={3}>
              {order.dropoff_notes}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <Row>
            <Col span='12'>
              <h3>Google Map</h3>
              {address && (
                <GoogleMapStatic
                  geom={{ lat: address.lat, lng: address.lng }}
                />
              )}
            </Col>

            <Col span='12'>
              <h3>Geocoded Result</h3>
              {compiledAddress}{' '}
              <a
                href={`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
                  compiledAddress
                )}&key=${process.env.REACT_APP_MAPS_API_KEY}`}
                target='_blank'
                rel='noreferrer'
              >
                Click here to see geocoding results
              </a>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab='Payment' key='3'>
          <Descriptions
            title='Payment Info'
            layout='horizontal'
            column={6}
            size='small'
          >
            {payment && (
              <>
                <Descriptions.Item label='Payment ID' span={3}>
                  <strong>{payment.data.id}</strong>{' '}
                  <Tag color='green'>
                    {payment.data.paid ? 'Paid' : 'Unpaid'}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label='Payment for' span={3}>
                  {payment.data.description}
                </Descriptions.Item>
                <Descriptions.Item label='Amount'>
                  £{payment.data.amount / 100}
                </Descriptions.Item>
                <Descriptions.Item label='Fee'>
                  £
                  {payment.data.balance_transaction
                    ? payment.data.balance_transaction.fee / 100
                    : 0}
                </Descriptions.Item>
                <Descriptions.Item label='Paid At'>
                  <Moment>{payment.data.created}</Moment>
                </Descriptions.Item>
                {payment.data && payment.data.source && (
                  <Descriptions.Item label='Paid By'>
                    {payment.data.source.brand} {payment.data.source.last4}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label='Captured'>
                  {payment.data.outcome.authorized}{' '}
                  {payment.data.outcome.reason}{' '}
                  {payment.data.outcome.seller_message}
                </Descriptions.Item>
                <Descriptions.Item label='Refunded'>
                  {payment.data.refunded}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </TabPane>
        <TabPane tab='Event tracker' key='4'>
          <Steps direction='vertical' size='small' current={event_logs.length}>
            {event_logs
              .sort((a, b) => {
                return +new Date(a.inserted_at) - +new Date(b.inserted_at)
              })
              .filter(
                (evt) =>
                  evt.payload.data.to !== 'almost_delivering' &&
                  evt.payload.data.to !== 'delivery'
              )
              .map((event) => (
                <Step
                  title={getEventTitle(event.payload)}
                  description={getEventDescription(event)}
                  key={event.id}
                />
              ))}
          </Steps>
        </TabPane>
        {order.status !== 'pending' && (
          <TabPane tab='Override' key='5'>
            <DeliveryControl {...{ client, order }} />
          </TabPane>
        )}
        <TabPane tab='SMS' key='6'>
          <PageHeader title='Send SMS to customer' />
          <SmsUtil to={customer_details.contact_num} />
        </TabPane>
        <TabPane tab='Raw' key='7'>
          <JSONView src={order} />
        </TabPane>
        <TabPane tab='Comtrex' key='8'>
          <Comtrex order={order} />
        </TabPane>
      </Tabs>
    </>
  )
}

export default OrderDetails
