import { useState, useEffect, useCallback } from 'react'
import {
  Table,
  PageHeader,
  Tag,
  Input,
  message,
  Row,
  Col,
  Select,
  Tabs
} from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@apollo/client'
import {
  CopyOutlined,
  CheckCircleOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { QUERY_MERCHANT_SIGNATURES } from './TermsQueries'
import { debounce } from 'lodash'
import moment from 'moment'
import './terms.css'

const { TabPane } = Tabs

const filters = [
  {
    key: 'all',
    label: 'All Partners'
  },
  {
    key: 'signed',
    label: 'Signed'
  },
  {
    key: 'unsigned',
    label: 'Not signed'
  }
]

const TermsTracker = (props) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const { client } = props
  const { loading, data } = useQuery(QUERY_MERCHANT_SIGNATURES, {
    client: client,
    fetchPolicy: 'no-cache'
  })

  const [signedPartners, setSignedPartners] = useState(data?.merchants)
  const [searchFilter, setSearchFilter] = useState('')
  const [filterBy, setFilterBy] = useState('all')
  const [copiedPartnerId, setCopiedPartnerId] = useState('')

  const filterPartners = useCallback(
    (partners) => {
      let tempPartners = Object.assign([], partners)

      if (filterBy === 'all') {
        tempPartners = Object.assign(
          [],
          tempPartners.filter((partner) => {
            return partner?.name?.includes('')
          })
        )
      }

      if (filterBy === 'signed') {
        tempPartners = Object.assign(
          [],
          tempPartners.filter((partner) => {
            return partner?.signatures[0]?.agreed
          })
        )
      }

      if (filterBy === 'unsigned') {
        tempPartners = Object.assign(
          [],
          tempPartners.filter((partner) => {
            return !partner?.signatures[0]?.agreed
          })
        )
      }

      if (searchFilter) {
        const parsedFilter = searchFilter.toLowerCase()
        tempPartners = Object.assign(
          [],
          tempPartners.filter((partner) => {
            const { name, registered_company_name, id } = partner
            return (
              registered_company_name?.toLowerCase().includes(parsedFilter) ||
              name?.toLowerCase().includes(parsedFilter) ||
              id.includes(parsedFilter)
            )
          })
        )
      }

      return tempPartners
    },
    [searchFilter, filterBy]
  )

  const debouncedFuzzySearch = debounce(setSearchFilter, 400)

  useEffect(() => {
    if (data) {
      const { merchants } = data
      setSignedPartners(filterPartners(merchants))
    }
  }, [filterPartners, data])

  useEffect(() => {
    const activeTime = setTimeout(() => {
      setCopiedPartnerId('')
    }, 2500)

    return () => clearTimeout(activeTime)
  }, [copiedPartnerId])

  const columns = [
    {
      title: 'Company name',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => {
        const displayName = record.registered_company_name || record.name

        return (
          <span>
            {displayName}(ID{' '}
            {copiedPartnerId === text ? (
              <CheckCircleOutlined className='copy-success' />
            ) : (
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(record.id)
                  setCopiedPartnerId(record.id)
                  message.success(
                    <span>
                      Copied ID of <strong>{displayName}</strong> to clipboard!
                    </span>,
                    2.5
                  )
                }}
                className='copy-id'
              />
            )}
            )
          </span>
        )
      },
      sorter: (a, b) => {
        return (a.registered_company_name || a.name)
          .toLowerCase()
          .localeCompare((b.registered_company_name || b.name).toLowerCase())
      }
    },
    {
      title: 'Partner first name',
      dataIndex: ['signatures'],
      key: 'first_name',
      render: (text, record) => (
        <span>
          {record?.signatures[0]?.first_name || (
            <span className='not-applicable'>n/a</span>
          )}
        </span>
      )
    },
    {
      title: 'Partner last name',
      dataIndex: ['signatures'],
      key: 'last_name',
      render: (text, record) => (
        <span>
          {record?.signatures[0]?.last_name || (
            <span className='not-applicable'>n/a</span>
          )}
        </span>
      )
    },
    {
      title: "T&C's signed",
      dataIndex: ['signatures'],
      key: 'agreed',
      render: (text, record) => {
        const hasSignedLatestTerms = record?.signatures[0]?.agreed

        return (
          <Tag color={`${hasSignedLatestTerms ? 'green' : 'red'}`}>
            {hasSignedLatestTerms ? 'Yes' : 'No'}
          </Tag>
        )
      }
    },
    {
      title: 'Date last signed',
      dataIndex: ['signatures'],
      key: 'merchants',
      render: (text, record) => {
        const latestSignature = record?.signatures[0]?.updated_at

        return (
          (latestSignature &&
            moment(latestSignature).format('DD/MM/YYYY HH:MM')) || (
            <span className='not-applicable'>n/a</span>
          )
        )
      }
    }
  ]

  return (
    <>
      <PageHeader title='T&Cs tracker' />
      <Row gutter={8} align='middle' className='terms-controls'>
        <Col span={6}>
          <Input
            onChange={(e) => debouncedFuzzySearch(e.target.value)}
            placeholder='Search merchant'
            suffix={<SearchOutlined />}
            data-testid='terms-search'
          />
        </Col>
        <Col span={8}>
          <Select
            defaultValue='all'
            onChange={(value) => setFilterBy(value)}
            className='terms-filter'
            data-testid='terms-filter'
          >
            {filters.map((f) => (
              <Select.Option
                key={f.key}
                value={f.key}
                data-testid={`terms-${f.key}`}
              >
                {f.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Tabs type='card'>
        <TabPane tab={`Active Partners (${signedPartners?.length || 0})`}>
          <Table
            dataSource={signedPartners}
            columns={columns}
            pagination={{
              defaultPageSize: 40,
              onChange: () => window.scrollTo(0, 0)
            }}
            size={isTabletOrMobileDevice ? 'small' : 'middle'}
            tableLayout='auto'
            loading={loading}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default TermsTracker
