import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import './App.less'
import './index.css'
import App from './App'
import Login from './components/Login/Login'
import client, { validToken, GRAPHQL_ENDPOINT } from './client'

ReactDOM.render(
  <ApolloProvider client={client}>
    {validToken() ? <App /> : <Login endpoint={GRAPHQL_ENDPOINT} />}
  </ApolloProvider>,
  document.getElementById('root')
)
