import { useState } from 'react'
import { Table, Tag } from 'antd'
import MerchantDrawer from './MerchantDrawer'
import moment from 'moment-timezone'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
// import MerchantWeeklyGraph from './MerchantWeeklyGraph'
// import { sumBy } from 'lodash'

const DOMAIN_SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX

const MerchantsList = ({
  merchants,
  client,
  loading,
  key,
  defaultSort = 'seven_days'
}) => {
  const [activeMerchant, setActiveMerchant] = useState()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <>
            <Link to={`/merchants/${record.slug}`}>{text}</Link>
          </>
        )
      },
      sorter: (a, b) => ('' + a.name).localeCompare(b.name)
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      render: (text) => String(text).toUpperCase()
    },
    {
      title: 'ICP',
      dataIndex: 'icp',
      key: 'icp',
      render: (text) => String(text || '').toUpperCase()
    },
    {
      title: 'Stores',
      dataIndex: 'stores_aggregate.aggregate.count',
      key: 'stores_count',
      render: (text, record) => {
        return (
          <>
            <Tag color='green'>{record?.stats?.open} open</Tag>
            <span></span>
            <Tag color='red'> {record?.stats?.closed} closed</Tag>
          </>
        )
      }
    },
    {
      title: 'Orders',
      dataIndex: ['stats', 'total_orders'],
      key: 'total_orders',
      render: (text, record) => {
        return <>£{text || 0}</>
      },
      sorter: (a, b) => a.stats && a.stats.total_orders - b.stats.total_orders
    },
    // {
    //   title: '7 Day Orders',
    //   key: 'seven_day_orders',
    //   defaultSortOrder: defaultSort === 'seven' ? ('descend' as const) : null,
    //   render: (record) => {
    //     return (
    //       <>
    //         {new Intl.NumberFormat('en-GB', {
    //           style: 'currency',
    //           currency: 'GBP'
    //         }).format(sumBy(record.weekly_sales, (s) => parseInt(s.total))) ||
    //           0}
    //       </>
    //     )
    //   },
    //   sorter: (a, b) =>
    //     sumBy(a.weekly_sales, (s) => parseInt(s.total)) -
    //     sumBy(b.weekly_sales, (s) => parseInt(s.total))
    // },
    // {
    //   title: '7 Days',
    //   key: 'seven_day_graph',
    //   render: (text, record) => {
    //     return <MerchantWeeklyGraph merchant={record} />
    //   }
    // },
    {
      title: 'Store',
      dataIndex: '',
      key: 'store',
      render: (text, record) => (
        <a
          href={`https://${record.slug}.${DOMAIN_SUFFIX}/order`}
          target='_blank'
          rel='noreferrer'
        >
          Visit Site
        </a>
      )
    },
    {
      title: 'Plan',
      dataIndex: ['stats', 'current_plan'],
      key: 'current_plan',
      render: (text, record) => text
    },
    {
      title: 'Published At',
      dataIndex: 'published_at',
      key: 'published_at',
      render: (text) => <Moment fromNow>{text}</Moment>,
      defaultSortOrder:
        defaultSort === 'publishedAt' ? ('descend' as const) : null,
      sorter: (a, b) =>
        b.published_at &&
        moment(a.published_at).unix() - moment(b.published_at).unix()
    },
    {
      title: 'Last Order',
      dataIndex: ['stats', 'last_ordered_at'],
      key: 'last_ordered_at',
      render: (text, record) => <Moment fromNow>{text}</Moment>,
      defaultSortOrder:
        defaultSort === 'lastOrder' ? ('descend' as const) : null,
      sorter: (a, b) =>
        a.stats &&
        new Date(a?.stats.last_ordered_at).getTime() -
          new Date(b?.stats.last_ordered_at).getTime()
    },
    {
      title: 'Orders',
      dataIndex: 'orders',
      key: 'store',
      render: (text, record) => (
        <Link to={`/merchants/${record.slug}/orders`}>Show Orders</Link>
      )
    }
  ]

  const onDrawerClose = () => {
    setActiveMerchant(null)
    setDrawerVisible(false)
  }

  return (
    <>
      <Table
        style={{ marginTop: '24px' }}
        dataSource={merchants}
        columns={columns}
        pagination={{ defaultPageSize: 50 }}
        loading={loading}
        key={key}
      />

      <MerchantDrawer
        merchant={activeMerchant}
        visible={drawerVisible}
        onClose={onDrawerClose}
      />
    </>
  )
}

export default MerchantsList
