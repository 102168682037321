import { useState } from 'react'
import { Button, Row, Col } from 'antd'
import JSONView from '../Utils/JSONView'
import fetch from 'isomorphic-fetch'

const Comtrex = ({ order }) => {
  const [comtrexPayload, setComtrexPayload] = useState({})
  const [posSnapshot, setPosSnapshot] = useState({})

  const COMTREX_HOST = process.env.REACT_APP_COMTREX_HOST
  const COMTREX_PREVIEW_URL = `${COMTREX_HOST}/api/comtrex/orderpreview?transactionId=${order.transaction_id}`
  const COMTREX_SNAPSHOT_URL = `${COMTREX_HOST}/api/comtrex/ordersnapshot?transactionId=${order.transaction_id}`

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Access-Control-Allow-Origin', '*')
  headers.append('Sec-Fetch-Mode', 'cors')
  headers.append('Sec-Fetch-Site', 'cross-site')

  const loadComtrex = () => {
    fetch(COMTREX_PREVIEW_URL, { headers, mode: 'cors' })
      .then((response) => response.json())
      .then((payload) => setComtrexPayload(payload))
  }

  const loadPOSSnapshot = () => {
    fetch(COMTREX_SNAPSHOT_URL, { headers, mode: 'cors' })
      .then((response) => response.json())
      .then((payload) => setPosSnapshot(payload))
  }

  return (
    <>
      <h3>This pane is for Comtrex integrated locations and partners only.</h3>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <div>
            <Button onClick={loadComtrex}>View Comtrex Payload</Button>
          </div>
          <JSONView src={comtrexPayload} />
        </Col>
        <Col span={12}>
          <div>
            <Button onClick={loadPOSSnapshot}>View POS Snapshot</Button>
          </div>
          <JSONView src={posSnapshot} />
        </Col>
      </Row>
    </>
  )
}

export default Comtrex
