import React from 'react'
import { ApolloConsumer } from '@apollo/client'
import AppContainer from './AppContainer'
import Moment from 'react-moment'
import 'moment-timezone'

Moment.globalTimezone = 'Europe/London'
Moment.globalFormat = 'DD/MM/YYYY HH:mm'

function App() {
  return (
    <div className='App'>
      <ApolloConsumer>
        {(client) => {
          return <AppContainer client={client} />
        }}
      </ApolloConsumer>
    </div>
  )
}

export default App
