import { PageHeader, Table, Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useMediaQuery } from 'react-responsive'
import { QUERY_COMMISSION_PLANS } from './Queries'
import { Decimal } from 'decimal.js'

const Commission = ({ client }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const { loading, data } = useQuery(QUERY_COMMISSION_PLANS, {
    client: client,
    fetchPolicy: 'no-cache'
  })

  const percentageDisplay = (numText: string) => {
    const value = new Decimal(Number(numText || 0))
    const percentage = value.times(100).toNumber()
    return <p>{percentage}%</p>
  }

  const columns = [
    {
      title: 'Plan',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'Plan ID',
      dataIndex: 'plan_id',
      key: 'plan_id'
    },
    {
      title: 'Delivery',
      dataIndex: 'delivery',
      key: 'delivery',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Same day self delivery',
      dataIndex: 'same_day_self_delivery',
      key: 'same_day_self_delivery',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Preorder Delivery',
      dataIndex: 'preorder_delivery',
      key: 'preorder_delivery',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Preorder Self Delivery',
      dataIndex: 'preorder_self_delivery',
      key: 'preorder_self_delivery',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Nationwide Delivery',
      dataIndex: 'nationwide_delivery',
      key: 'nationwide_delivery',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Pickup',
      dataIndex: 'pickup',
      key: 'pickup',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Preorder Pickup',
      dataIndex: 'preorder_pickup',
      key: 'preorder_pickup',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Table Orders',
      dataIndex: 'table',
      key: 'table',
      render: (text) => {
        return percentageDisplay(text)
      }
    }
  ]

  return (
    <>
      <Row align='middle'>
        <Col span={12}>
          <PageHeader
            title='Commission Plans'
            subTitle='This shows all commission plans'
          />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to='/commission-plans/new' style={{ marginRight: '16px' }}>
            <Button type='primary'>Create commission plan</Button>
          </Link>
        </Col>
      </Row>
      <Table
        dataSource={data?.commission_rates || []}
        columns={columns}
        pagination={{ defaultPageSize: 40 }}
        size={isTabletOrMobileDevice ? 'small' : 'middle'}
        tableLayout='auto'
        loading={loading}
      />
    </>
  )
}
export default Commission
