import { useEffect, useState } from 'react'
import { PageHeader, Tabs, Radio, Row, Col } from 'antd'
import { QUERY_MERCHANTS, INVALID_MERCHANTS } from './MerchantQueries'
import MerchantsList from './MerchantsList'
import { Select, Alert } from 'antd'
import { filter } from 'lodash'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { SECTORS, ICPS } from './MerchantSettings'

const { Option } = Select
const { TabPane } = Tabs

const MerchantsContainer = ({ client }) => {
  const { loading, data, refetch, networkStatus } = useQuery(QUERY_MERCHANTS, {
    variables: {
      sector: null,
      icp: null,
      limit: 2500,
      isNull: false
    },
    notifyOnNetworkStatusChange: true
  })

  const [, setFilteredMerchants] = useState([])
  const [currentSector, setCurrentSector] = useState('all')
  const [currentIcp, setCurrentIcp] = useState('all')
  const [activeMerchants, setActiveMerchants] = useState([])
  const [inactiveMerchants, setInactiveMerchants] = useState([])
  const [noTransactions, setNoTransactions] = useState([])
  const [lastOrder, setLastOrder] = useState(7) // 7 days

  const sortMerchantsToDefault = (merchants) => {
    return merchants
  }

  useEffect(() => {
    setFilteredMerchants(data?.merchants)
    const filteredActive = filter(
      data?.merchants,
      (m) => moment().diff(moment(m.stats.last_ordered_at), 'days') < lastOrder
    )
    const filteredInactive = filter(
      data?.merchants,
      (m) =>
        !m.stats.last_ordered_at ||
        moment().diff(moment(m.stats.last_ordered_at), 'days') >= lastOrder
    )

    const filteredNotransactions = filter(
      data?.merchants,
      (m) => !m.stats.last_ordered_at
    )

    setActiveMerchants(sortMerchantsToDefault(filteredActive))
    setInactiveMerchants(sortMerchantsToDefault(filteredInactive))
    setNoTransactions(sortMerchantsToDefault(filteredNotransactions))
  }, [networkStatus, data?.merchants, lastOrder])

  useEffect(() => {
    if (!data?.merchants) return

    let sector = currentSector
    let icp = currentIcp

    if (sector === 'all' || sector === 'unknown') {
      sector = null
    }

    if (currentIcp === 'all') {
      icp = null
    }
    refetch({
      sector,
      icp,
      limit: 2500,
      isNull: sector === 'unknown' ? true : false
    }).then((result) => {
      if (sector === 'unknown') {
        const filtered = filter(result.data.merchants, (m) => m.sector === null)
        setFilteredMerchants(filtered)
      } else {
        setFilteredMerchants(result.data.merchants)
      }
    })
  }, [currentSector, currentIcp])

  const InvalidPartners = () => {
    const [invalidSlugs, setInvalidSlugs] = useState([])
    const { data: invalidMerchantData } = useQuery(INVALID_MERCHANTS)

    useEffect(() => {
      if (invalidMerchantData && invalidMerchantData.merchants.length > 0) {
        setInvalidSlugs(
          invalidMerchantData.merchants.map((merchant) => (
            <>
              <a href={`/merchants/${merchant.slug}/settings`}>
                {merchant.name}
              </a>
              {', '}
            </>
          ))
        )
      }
    }, [invalidMerchantData])

    return (
      invalidSlugs.length > 0 && (
        <Alert
          message='These partners require attention'
          description={
            <>
              These partners have missing sectors. {invalidSlugs}
              <p>Please update their sectors in the partner settings.</p>
            </>
          }
          showIcon
          type='error'
        />
      )
    )
  }

  return (
    <div className='merchants'>
      <>
        <Row align='bottom'>
          <Col>
            <PageHeader title='Partners' />
          </Col>
          <Col flex='auto'>
            <Row align='middle'>
              <Col>
                <Select
                  defaultValue='all'
                  value={currentSector}
                  style={{ marginRight: '8px' }}
                  onChange={(value) => {
                    setCurrentSector(value)
                    return true
                  }}
                >
                  <Option value='all'>All Sectors</Option>
                  {Object.entries(SECTORS).map((sector) => {
                    return <Option value={sector[0]}>{sector[1]}</Option>
                  })}
                </Select>
              </Col>
              <Col>
                <Select
                  defaultValue='all'
                  value={currentIcp}
                  style={{ marginRight: '8px' }}
                  onChange={(value) => {
                    setCurrentIcp(value)
                    return true
                  }}
                >
                  <Option value='all'>All ICP</Option>
                  {Object.entries(ICPS).map((icp) => {
                    return <Option value={icp[0]}>{icp[1]}</Option>
                  })}
                </Select>
              </Col>
              <Col>
                <span style={{ marginRight: '8px' }}>Last Transacted:</span>
              </Col>
              <Col>
                <Radio.Group
                  onChange={(e) => {
                    setLastOrder(parseInt(e.target.value))
                  }}
                  defaultValue='7'
                >
                  <Radio.Button value='1'>24h</Radio.Button>
                  <Radio.Button value='7'>7d</Radio.Button>
                  <Radio.Button value='30'>30d</Radio.Button>
                  <Radio.Button value='60'>60d</Radio.Button>
                  <Radio.Button value='90'>90d</Radio.Button>
                  <Radio.Button value='180'>180d</Radio.Button>
                  <Radio.Button value='365'>365d</Radio.Button>
                  <Radio.Button value='31600'>All Time</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <InvalidPartners />
        </Row>
        <Tabs
          defaultActiveKey='1'
          type='card'
          size='large'
          style={{ marginTop: 16 }}
        >
          <TabPane
            tab={`Active (${activeMerchants.length})`}
            key='1'
            style={{ overflowX: 'auto' }}
          >
            <MerchantsList
              merchants={activeMerchants}
              client={client}
              loading={loading}
              key='active'
              defaultSort='seven'
            />
          </TabPane>
          <TabPane
            tab={`Inactive (${inactiveMerchants.length})`}
            key='2'
            style={{ overflowX: 'auto' }}
          >
            <MerchantsList
              merchants={inactiveMerchants}
              client={client}
              loading={loading}
              key='inactive'
              defaultSort='lastOrder'
            />
          </TabPane>
          <TabPane
            tab={`No Transactions (${noTransactions.length})`}
            key='3'
            style={{ overflowX: 'auto' }}
          >
            <MerchantsList
              merchants={noTransactions}
              client={client}
              loading={loading}
              key='notransactions'
              defaultSort='publishedAt'
            />
          </TabPane>
        </Tabs>
      </>
    </div>
  )
}

export default MerchantsContainer
