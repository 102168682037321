import { Table, Tag, Typography } from 'antd'

import moment from 'moment-timezone'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'

const DOMAIN_SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX
const CONTROLS_HOST = process.env.REACT_APP_CONTROLS_HOST

const MerchantsList = ({
  merchants,
  client,
  loading,
  key,
  defaultSort = 'seven_days'
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <>
            <Link to={`/merchants/${record.slug}`}>{text}</Link>(
            <Typography.Text copyable={{ text: record.id }} type='secondary'>
              ID
            </Typography.Text>
            )
          </>
        )
      },
      sorter: (a, b) => ('' + a.name).localeCompare(b.name)
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug'
    },
    {
      title: 'Deliverect',
      dataIndex: 'deliverect',
      render: (text, record) =>
        record.setting.deliverect_id ? (
          <Tag color='green'>Yes</Tag>
        ) : (
          <Tag>No</Tag>
        )
    },
    {
      title: 'Stores',
      dataIndex: 'stores_count',
      render: (text, record) => record.stores.length
    },
    {
      title: 'Store',
      dataIndex: '',
      key: 'store',
      render: (text, record) => (
        <a
          href={`https://${record.slug}.${DOMAIN_SUFFIX}/order`}
          target='_blank'
          rel='noreferrer'
        >
          Visit Site
        </a>
      )
    },
    {
      title: 'Published At',
      dataIndex: 'published_at',
      key: 'published_at',
      render: (text) => <Moment fromNow>{text}</Moment>,
      defaultSortOrder:
        defaultSort === 'publishedAt' ? ('descend' as const) : null,
      sorter: (a, b) =>
        b.published_at &&
        moment(a.published_at).unix() - moment(b.published_at).unix()
    },
    {
      title: 'Inserted At',
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      render: (text) => <Moment fromNow>{text}</Moment>,
      defaultSortOrder:
        defaultSort === 'insertedAt' ? ('descend' as const) : null,
      sorter: (a, b) =>
        b.inserted_at &&
        moment(a.inserted_at).unix() - moment(b.inserted_at).unix()
    },
    {
      title: 'Sign in',
      key: 'sign_in',
      render: (text, record) => (
        <a
          href={`${CONTROLS_HOST}/sso?merchant=${
            record.id
          }&token=${localStorage.getItem('token')}`}
        >
          Sign In to Dashboard
        </a>
      )
    }
  ]
  return (
    <>
      <Table
        style={{ marginTop: '24px' }}
        dataSource={merchants}
        columns={columns}
        pagination={{ defaultPageSize: 50 }}
        loading={loading}
        key={key}
      />
    </>
  )
}

export default MerchantsList
