import React from 'react'
import GoogleMapReact from 'google-map-react'
import { QUERY_ORDER_LOCATIONS } from './OrderQueries'
import { useQuery } from '@apollo/react-hooks'

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY

const OrderMapContainer = (props) => {
  const Map = () => {
    const { data } = useQuery(QUERY_ORDER_LOCATIONS, {
      client: props.client
    })

    let heatMapPoints = []
    if (data && data.view_order_coords) {
      heatMapPoints = data.view_order_coords.map((order) => {
        const { lat, lng } = order.coords
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      })
    }

    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          key: MAPS_API_KEY
        }}
        defaultCenter={{ lat: 51.5137137, lng: -0.1285204 }}
        defaultZoom={13}
        heatmapLibrary={true}
        heatmap={{
          positions: heatMapPoints,
          options: {
            radius: 30,
            opacity: 1
          }
        }}
      />
    )
  }

  return <Map />
}

export default OrderMapContainer
