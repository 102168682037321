import React, { useCallback, SyntheticEvent, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Subscription } from '@apollo/client/react/components'
import OrdersList from '../../Orders/OrdersList'
import OrderDrawer from '../../Orders/OrderDrawer'
import { Skeleton, Row, Tabs, Col, Input, Badge, Select } from 'antd'
import { buildQuery, buildOpsQuery } from '../../Orders/OrderQueries'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'

const startOfDay = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
const endOfDay = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

const queries = {
  default: '{}',
  large: '{total: {_gt: 100}}',
  pending: '{status: {_in: ["pending"]}}',
  pendingAsap: `{inserted_at: {_gte: "${startOfDay}"}, status: {_in: ["pending"]}, fulfillment_type: {_eq: "delivery"}, _or: [{fulfillment_date: {_gte: "${startOfDay}", _lte: "${endOfDay}"}}, {fulfillment_date: {_is_null: true}}]}`,
  integrations:
    '{merchant: {partners_merchants: {partner: {name: {_in: ["deliverect", "comtrex"]}}}}}',
  refunded: '{status: {_in: ["refunded"]}}'
}

interface OrdersContainerProps {
  client: any
  search?: string
}

const OrdersContainer = ({ client, search }: OrdersContainerProps) => {
  const [activeOrder, setActiveOrder] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)

  const showDrawer = (record) => {
    setActiveOrder(record)
    setDrawerVisible(true)
    return false
  }

  const onDrawerClose = () => {
    setActiveOrder(null)
    setDrawerVisible(false)
  }

  const onSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    if (value && value.length === 6) {
      // history.push(`/orders/${String(value)}`)
      showDrawer({
        transaction_id: String(value).toUpperCase()
      })
    }
  }

  const [query, setQuery] = useState(queries['default'])
  const [limit, setLimit] = useState(50)
  const { data } = useQuery(buildOpsQuery(50), {
    pollInterval: 60000 // every 60 seconds
  })

  const asapOrdersCount = useCallback(() => {
    if (!data) return 0

    return data.orders.filter(
      (order) =>
        order.fulfillment_type === 'delivery' &&
        order.store?.deliverect_link_id === null &&
        new Date(order.inserted_at) >= moment().startOf('day').toDate()
    ).length
  }, [data?.orders])

  const integrationsCount = useCallback(() => {
    if (!data) return 0

    const integrationOrders = data.orders.filter(
      (order) => order.store?.deliverect_link_id
    )

    const failedOrders = integrationOrders.filter(
      (order) => !order.metadata?.deliverect_order_id
    )

    return failedOrders.length
  }, [data?.orders])

  const changeTab = (key) => {
    setQuery(queries[key])
    return true
  }

  useEffect(() => {
    if (search && search.length === 6) {
      showDrawer({
        transaction_id: String(search).toUpperCase()
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row align='middle' gutter={[16, 16]} justify='space-between'>
        <Col>
          <Row gutter={[16, 16]}>
            <Col>
              <Tabs onTabClick={(key) => changeTab(key)}>
                <Tabs.TabPane
                  tab='Latest Orders'
                  key='default'
                  active
                ></Tabs.TabPane>
                <Tabs.TabPane tab='Pending' key='pending'></Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <>
                      Pending Delivery{' '}
                      {asapOrdersCount() > 0 ? (
                        <Badge count={asapOrdersCount()} status='error' />
                      ) : null}
                    </>
                  }
                  key='pendingAsap'
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <>
                      POS Orders{' '}
                      {integrationsCount() > 0 ? (
                        <Badge count={integrationsCount()} status='error' />
                      ) : null}
                    </>
                  }
                  key='integrations'
                ></Tabs.TabPane>
                <Tabs.TabPane tab='Large Orders' key='large'></Tabs.TabPane>
                <Tabs.TabPane tab='Refunded' key='refunded'></Tabs.TabPane>
              </Tabs>
            </Col>
            <Col>
              <Input
                placeholder='Search order by transaction id. e.g. T3XJQM'
                onChange={onSearch}
                onSubmit={onSearch}
                defaultValue={search}
                suffix={<SearchOutlined />}
                style={{ minWidth: '320px', maxWidth: '600px' }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <span>No. of rows: </span>
          <Select onSelect={(val) => setLimit(Number(val))} defaultValue={50}>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
            <Select.Option value={300}>300</Select.Option>
            <Select.Option value={500}>500</Select.Option>
            <Select.Option value={1000}>1000</Select.Option>
          </Select>
        </Col>
      </Row>
      <div className='orders'>
        <Subscription subscription={buildQuery(query, limit)}>
          {({ data, error, loading }) => {
            if (loading) {
              return <Skeleton paragraph={{ rows: 10 }} active loading />
            }
            if (error || (data && data.message === null)) {
              return error.message
            }
            if (data && data.orders) {
              return <OrdersList orders={data.orders} showDrawer={showDrawer} />
            }
            return null
          }}
        </Subscription>
      </div>
      <OrderDrawer
        order={activeOrder}
        client={client}
        visible={drawerVisible}
        onClose={onDrawerClose}
      />
    </>
  )
}

export default OrdersContainer
