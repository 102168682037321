import React from 'react'
import { Subscription } from '@apollo/client/react/components'
import { SUBSCRIPTION_LATEST_ORDERS } from './HomeQueries'
import OrdersList from './OrdersList'
import { Skeleton } from 'antd'

const OrdersContainer = ({ client }) => {
  return (
    <>
      <div className='orders-filter'></div>
      <div className='orders'>
        <Subscription subscription={SUBSCRIPTION_LATEST_ORDERS}>
          {({ data, error, loading }) => {
            if (loading) {
              return <Skeleton paragraph={{ rows: 10 }} active loading />
            }
            if (error || (data && data.message === null)) {
              return error.message
            }
            if (data && data.orders) {
              return <OrdersList orders={data.orders} client={client} />
            }
            return null
          }}
        </Subscription>
      </div>
    </>
  )
}

export default OrdersContainer
