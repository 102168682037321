import { useState } from 'react'
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { Form, PageHeader, Switch, Input, Button, message } from 'antd'

const UPDATE_COMPOSER = gql`
  mutation updateIntegrations($merchantId: uuid!, $setting: jsonb!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _append: { integration_settings: $setting }
    ) {
      affected_rows
      returning {
        integration_settings
      }
    }
  }
`

const CORRECT_COMPOSER_SETTINGS = gql`
  mutation updateIntegrations($merchantId: uuid!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _set: { integration_settings: {} }
    ) {
      affected_rows
      returning {
        integration_settings
      }
    }
  }
`

const ComposerForm = (props) => {
  const { merchant, client } = props
  const [composerId, setComposerId] = useState(
    merchant?.integration_settings?.composer?.id
  )
  const [composerEnabled, setComposerEnabled] = useState(
    merchant.integration_settings?.composer?.enabled || false
  )

  const [correctIntegrationSettings] = useMutation(CORRECT_COMPOSER_SETTINGS, {
    client: client
  })

  if (!merchant?.integration_settings) {
    correctIntegrationSettings({
      variables: {
        merchantId: merchant.id
      }
    })
  }

  const [updateComposer] = useMutation(UPDATE_COMPOSER, {
    client: client
  })

  const handleToggle = () => {
    message.loading('Enabling Composer... Please wait.')
    const newValue = !composerEnabled
    setComposerEnabled(newValue)
    updateComposer({
      variables: {
        merchantId: merchant.id,
        setting: {
          composer: {
            enabled: newValue,
            id: composerId
          }
        }
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Composer enabled...', 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    message.loading('Updating composer settings... Please wait.')
    updateComposer({
      variables: {
        merchantId: merchant.id,
        setting: {
          composer: {
            enabled: composerEnabled,
            id: composerId
          }
        }
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Composer updated', 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  return (
    <>
      <PageHeader title='Composer' />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Enable Composer'>
          <Switch
            defaultChecked={
              merchant?.integration_settings?.composer?.enabled ? true : false
            }
            onChange={handleToggle}
            checkedChildren='Enabled'
            unCheckedChildren='Disabled'
          />
        </Form.Item>

        <Form.Item label='Composer ID'>
          <Input
            name='id'
            defaultValue={composerId}
            onChange={(e) => {
              setComposerId(e.target.value)
            }}
          />
          <br />
          <br />
          <Button onClick={handleUpdate}>Save</Button>
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default ComposerForm
