import { Rule } from 'antd/es/form'

const urlRule = {
  type: 'url',
  message: 'Please input a valid URL'
} as Rule

const requiredRule = {
  required: true,
  message: 'This is a required field'
} as Rule

export { urlRule, requiredRule }
