import { gql } from '@apollo/client'

const QUERY_MERCHANT_SIGNATURES = gql`
  query getMerchantSignatures {
    merchants(
      where: { published_at: { _is_null: false } }
      order_by: {
        signatures_aggregate: { max: { updated_at: desc_nulls_last } }
      }
    ) {
      registered_company_name
      name
      published_at
      id
      signatures(order_by: { updated_at: desc_nulls_last }, limit: 1) {
        first_name
        last_name
        agreed
        updated_at
      }
    }
  }
`

export { QUERY_MERCHANT_SIGNATURES }
