interface RateCardImportPayload {
  bike: { [key: string]: string }
  motorbike: { [key: string]: string }
  car: { [key: string]: string }
}

const ReadRateCardImportFile = (file) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.readAsText(file)
    fileReader.onloadend = () => {
      resolve(fileReader.result)
    }
  })
}

const ValidateRateCardPayload = (payload: RateCardImportPayload) => {
  const invalidHeaders = []
  const invalidRowValues = []
  const errorMessages = []
  // check if column headers are numeric
  Object.keys(payload).forEach((key: string) => {
    const headers = Object.keys(payload[key]).filter(
      (columnHeader: string) => columnHeader && isNaN(Number(columnHeader))
    )
    if (!!headers.length) invalidHeaders.push(key)
  })
  if (!!invalidHeaders.length)
    errorMessages.push(
      `${invalidHeaders.join(', ')} have invalid header values`
    )

  // check if row values are numeric
  Object.keys(payload).forEach((key: string) => {
    const rowValues = Object.values(payload[key]).filter(
      (rowValue: string) => rowValue && isNaN(Number(rowValue))
    )
    if (!!rowValues.length)
      invalidRowValues.push(`${key} ${rowValues.join(', ')}`)
  })
  if (!!invalidRowValues.length)
    errorMessages.push(
      `Rows have invalid values such as ${invalidRowValues.join(', ')}`
    )

  return {
    valid: errorMessages.length === 0,
    errorMessages: errorMessages.join('. ')
  }
}

const SanitizeRateCardImportPayload = (payload: RateCardImportPayload) => {
  const { bike, motorbike, car } = payload
  const bikeRow = Object.keys(bike)
    .filter((key: string) => !isNaN(parseFloat(key)))
    .reduce((obj: object, key: string) => {
      obj[key] = bike[key]
      return obj
    }, {})
  const motorbikeRow = Object.keys(motorbike)
    .filter((key: string) => !isNaN(parseFloat(key)))
    .reduce((obj: object, key: string) => {
      obj[key] = motorbike[key]
      return obj
    }, {})
  const carRow = Object.keys(car)
    .filter((key: string) => !isNaN(parseFloat(key)))
    .reduce((obj: object, key: string) => {
      obj[key] = car[key]
      return obj
    }, {})

  return {
    car: carRow,
    bike: bikeRow,
    motorbike: motorbikeRow
  }
}

export {
  SanitizeRateCardImportPayload,
  ReadRateCardImportFile,
  ValidateRateCardPayload
}
