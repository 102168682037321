import {
  PageHeader,
  Table,
  Row,
  Col,
  Button,
  Switch,
  notification,
  Popconfirm
} from 'antd'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import { useMediaQuery } from 'react-responsive'
import {
  QUERY_TRANSACTION_PLANS,
  UPDATE_DEFAULT_TRANSACTION_PLAN
} from './Queries'
import { Decimal } from 'decimal.js'

const TransactionPlan = ({ client }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const { loading, data, refetch } = useQuery(QUERY_TRANSACTION_PLANS, {
    client: client,
    fetchPolicy: 'no-cache'
  })
  const [
    updateDefault,
    { loading: updateLoading }
  ] = useMutation(UPDATE_DEFAULT_TRANSACTION_PLAN, { client })

  const percentageDisplay = (numText: string) => {
    const value = new Decimal(Number(numText || 0))
    const percentage = value.times(100).toNumber()
    return <p>{percentage}%</p>
  }

  const updateDefaultPlan = (id: string) => {
    updateDefault({ variables: { id: id } })
      .then(() => {
        notification['success']({
          message: 'Successfully updated default transaction plan'
        })
        refetch()
      })
      .catch((error) => {
        notification['error']({
          message: 'Failed to update default transaction plan'
        })
      })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'stripe_account_type',
      key: 'stripe_account_type',
      render: (text) => {
        if (text === 'slerp_pay') {
          return 'SLERP PAY'
        }
        if (text === 'standard') {
          return 'STANDARD'
        }
        return text
      }
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Amex Percentage',
      dataIndex: 'amex_percentage',
      key: 'amex_percentage',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'International Percentage',
      dataIndex: 'international_percentage',
      key: 'international_percentage',
      render: (text) => {
        return percentageDisplay(text)
      }
    },
    {
      title: 'Fixed fee',
      dataIndex: 'fixed_fee',
      key: 'fixed_fee',
      render: (text) => {
        return `£ ${text}`
      }
    },
    {
      title: 'Default',
      dataIndex: 'defaulted_at',
      key: 'defaulted_at',
      render: (value, record) => {
        if (record.stripe_account_type === 'slerp_pay') {
          return (
            <Popconfirm
              title='Do you wish to make this the default? All new partners will automatically be set to this plan in the future.'
              onConfirm={() => updateDefaultPlan(record.id)}
            >
              <Switch
                checked={value}
                loading={updateLoading}
                checkedChildren='Default'
                disabled={value}
              />
            </Popconfirm>
          )
        }
      }
    }
  ]

  return (
    <>
      <Row align='middle'>
        <Col span={12}>
          <PageHeader
            title='Transaction Plans'
            subTitle='This shows all transaction plans'
          />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to='/transaction-plans/new' style={{ marginRight: '16px' }}>
            <Button type='primary'>Create transaction plan</Button>
          </Link>
        </Col>
      </Row>
      <Table
        dataSource={data?.transaction_plans || []}
        columns={columns}
        pagination={{ defaultPageSize: 40 }}
        size={isTabletOrMobileDevice ? 'small' : 'middle'}
        tableLayout='auto'
        loading={loading}
      />
    </>
  )
}

export default TransactionPlan
