import React from 'react'
import { Row, Col, Card, PageHeader } from 'antd'
import { AreaChart, BarChart } from 'react-chartkick'
import 'chart.js'
import { Query } from '@apollo/client/react/components'
import { QUERY_WEEKLY_ORDER_STATUSES, QUERY_HOURLY_ORDERS } from './HomeQueries'

import OrdersContainer from './OrdersContainer'
import MerchantsContainer from './MerchantsContainer'
import OpsStats from './Ops/OpsStats'
import OpsGMVCharts from './Ops/OpsGMVCharts'

const HomeContainer = (props) => {
  const { client } = props

  return (
    <>
      <OpsStats client={client} />

      <div style={{ marginBottom: '8x' }} />

      <OpsGMVCharts client={client} />

      <div style={{ marginBottom: '24px' }} />

      <Row>
        <Col xl={16} lg={24}>
          <PageHeader title='Hourly GMV' />
          <Query query={QUERY_HOURLY_ORDERS}>
            {({ data, loading }) => {
              if (loading) {
                return <Card title='Hourly orders' loading={loading} />
              }
              if (data && data.view_hourly_orders) {
                const hourlyGMV = {}
                const hourlyCount = {}

                data.view_hourly_orders.forEach((item) => {
                  hourlyCount[item.hour] = item.count
                  hourlyGMV[item.hour] = item.gmv
                  return [item.hour, item.gtv]
                })

                const chartData = [{ name: 'GMV', data: hourlyGMV }]

                return (
                  <>
                    <AreaChart
                      data={chartData}
                      colors={['#e1f887']}
                      prefix={'£'}
                      thousands=','
                      dataset={{ borderWidth: 0, borderSkipped: false }}
                    />
                  </>
                )
              }
              return null
            }}
          </Query>
        </Col>

        <Col xl={8} lg={24}>
          <PageHeader title='7 day stats' />
          <Query query={QUERY_WEEKLY_ORDER_STATUSES}>
            {({ data, loading }) => {
              if (loading) {
                return <Card title='7 day stats' loading={loading} />
              }
              if (data && data.view_order_weekly_statuses) {
                const chartData = data.view_order_weekly_statuses.map(
                  (item) => [item.status, item.count]
                )
                return (
                  <>
                    <BarChart
                      data={chartData}
                      colors={[
                        ['#E1F887', '#F5AB9A', '#9AE0F5', '#F5EC9A', '#E5E5E5']
                      ]}
                      dataset={{ borderWidth: 0, borderSkipped: false }}
                    />
                  </>
                )
              }
              return
            }}
          </Query>
        </Col>
      </Row>

      <div style={{ marginBottom: '24px' }} />
      <Row gutter={24}>
        <Col xl={10} lg={24}>
          <MerchantsContainer client={client} />
        </Col>
        <Col xl={14} lg={24}>
          <OrdersContainer client={client} />
        </Col>
      </Row>
    </>
  )
}

export default HomeContainer
