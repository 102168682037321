import { useState } from 'react'
import {
  Button,
  Col,
  InputNumber,
  Input,
  Form,
  Row,
  message,
  PageHeader,
  Popconfirm
} from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { CREATE_COMMISSION_PLANS, QUERY_COMMISSION_BY_SLUG } from './Queries'
import { required, validSlug } from './Rules'
import { v4 as uuidv4 } from 'uuid'
import { Decimal } from 'decimal.js'
import { Link } from 'react-router-dom'

const { useForm } = Form

const CommissionForm = ({ client }) => {
  const [form] = useForm()
  const [createCommissionPlan] = useMutation(CREATE_COMMISSION_PLANS, {
    client
  })
  const [submitLoading, setSubmitLoading] = useState(false)

  const createPlan = (values) => {
    const params = {
      ...values,
      delivery: percentageToNumber(values.delivery),
      pickup: percentageToNumber(values.pickup),
      preorder_delivery: percentageToNumber(values.preorder_delivery),
      nationwide_delivery: percentageToNumber(values.nationwide_delivery),
      preorder_pickup: percentageToNumber(values.preorder_pickup),
      table: percentageToNumber(values.table),
      same_day_self_delivery: percentageToNumber(values.same_day_self_delivery),
      preorder_self_delivery: percentageToNumber(values.preorder_self_delivery)
    }

    setSubmitLoading(true)
    message.loading('Creating commission plan...', 1)
    createCommissionPlan({
      variables: { ...params, id: uuidv4() }
    })
      .then(() => {
        message.destroy()
        message.success('Commission plan successfully created!', 1)
        window.setTimeout(() => {
          window.location.pathname = '/commission-plans'
        }, 1000)
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to create commission plan. Please check errors below and try again or contact tech support. Error: ${error}`,
          10
        )
        setSubmitLoading(false)
      })
  }

  const validateUniqueSlug = (_, slug: string) => {
    return client
      .query({
        query: QUERY_COMMISSION_BY_SLUG,
        variables: {
          slug: slug
        },
        fetchPolicy: 'no-cache'
      })
      .then((result) => {
        if (result.loading) return Promise.reject('Loading...')
        if (result?.data?.commission_rates?.length > 0)
          return Promise.reject('This slug has been taken')
        return Promise.resolve()
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  const parsePercentage = (percentage: string) => {
    const stringNum = percentage?.replace('%', '') || 0
    const value = new Decimal(Number(stringNum))
    return value.toDecimalPlaces(2).toNumber()
  }

  const suggestSlug = (value) => {
    if (value) {
      form.setFieldsValue({ slug: slugify(value) })
    }
  }

  const slugify = (text) => {
    return text
      .toString()
      .normalize('NFKD')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
  }

  const percentageToNumber = (value) => {
    const decimal = new Decimal(value)
    return decimal.dividedBy(100).toDecimalPlaces(4).toNumber()
  }

  return (
    <>
      <PageHeader title='Create new commission plan' />
      <Form
        form={form}
        onFinish={createPlan}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
        layout='vertical'
        scrollToFirstError={true}
        initialValues={{
          delivery: 0,
          pickup: 0,
          preorder_delivery: 0,
          nationwide_delivery: 0,
          preorder_pickup: 0,
          table: 0,
          same_day_self_delivery: 0,
          preorder_self_delivery: 0,
          plan_id: ''
        }}
      >
        <Form.Item name='name' label='Name' rules={[...required]}>
          <Input
            type='text'
            maxLength={80}
            onChange={(e) => suggestSlug(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name='slug'
          label='Slug'
          rules={[...required, ...validSlug, { validator: validateUniqueSlug }]}
        >
          <Input type='text' maxLength={80} />
        </Form.Item>
        <Form.Item
          name='plan_id'
          label={
            <>
              Plan Id. Stripe pricing id.{' '}
              <a href='https://dashboard.stripe.com/products?active=true'>
                Please create the Stripe product first.
              </a>
            </>
          }
          rules={[...required]}
        >
          <Input type='text' maxLength={80} />
        </Form.Item>
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item name='delivery' label='Delivery' rules={[...required]}>
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='pickup' label='Pickup' rules={[...required]}>
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name='preorder_delivery'
              label='Preorder Delivery'
              rules={[...required]}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name='nationwide_delivery'
              label='Nationwide Delivery'
              rules={[...required]}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item
              name='preorder_pickup'
              label='Preorder Pickup'
              rules={[...required]}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='table' label='Table' rules={[...required]}>
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name='same_day_self_delivery'
              label='Same Day Self Delivery'
              rules={[...required]}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name='preorder_self_delivery'
              label='Preorder Self Delivery'
              rules={[...required]}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                defaultValue={0}
                parser={parsePercentage}
                required
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Link to='/commission-plans' style={{ marginRight: '16px' }}>
                <Button type='ghost'>Discard</Button>
              </Link>
              <Popconfirm
                title='Are you sure you want to create this plan?'
                onConfirm={() => {
                  form.submit()
                }}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={submitLoading}
                >
                  Create
                </Button>
              </Popconfirm>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default CommissionForm
