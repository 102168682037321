import React, { useEffect, useState } from 'react'
import { Row, Col, DatePicker, Divider, PageHeader, Layout } from 'antd'
import 'chart.js'
import moment from 'moment-timezone'
import { GenericChart } from '../Accounting/Charts'
import { MONTHLY_BINS } from '../Accounting/ChartQueries'

const { RangePicker } = DatePicker
const Summaries = (props) => {
  const { client } = props
  const currentDate = new Date()
  const previousYear = new Date('2022-01-01')

  const [startDate, setStartDate] = useState<Date>(new Date(previousYear))
  const [endDate, setEndDate] = useState<Date>(currentDate)
  const [monthlyBins, setMonthlyBins] = useState([])
  // const [months, setMonths] = useState<string[]>([])
  const [dataSource, setDataSource] = useState<object[]>()

  const queryBins = async () => {
    return await client.query({
      query: MONTHLY_BINS,
      variables: {
        startDate,
        endDate
      },
      notifyOnNetworkStatusChange: true
    })
  }

  const loadBins = async () => {
    await queryBins().then(async ({ data }) => {
      setMonthlyBins(data?.view_monthly_kpi_totals)
    })
  }

  const handleRangePicker = (dates) => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  useEffect(() => {
    loadBins()
  }, [])

  useEffect(() => {
    loadBins()
  }, [startDate, endDate])

  const loadAll = async () => {
    const data = monthlyBins.map((kpi) => {
      const { month } = kpi
      const currentMonth = moment(month).isSame(moment(), 'month')
      const totalDayCount = currentMonth
        ? moment(new Date()).date()
        : moment(month).daysInMonth()

      const {
        gmv,
        gtv,
        merchants,
        count,
        stores,
        courier_fee,
        slerp_fee,
        application_fee,
        asap,
        ssd,
        pre,
        table_order
      } = kpi

      const all_income = Math.floor(
        Number(slerp_fee) + Number(courier_fee) + Number(application_fee)
      )

      return {
        gmv: Math.floor(gmv),
        gtv: Math.floor(gtv),
        merchants,
        commissions: Math.floor(slerp_fee),
        courier_fee: Math.floor(courier_fee),
        slerp_pay: Math.floor(application_fee),
        all_income,
        transactions: count,
        outlets: stores,
        avg_per_day: gmv / totalDayCount,
        avg_basket_size: gmv / count / 1.2,
        asap: asap - table_order,
        ssd: ssd,
        preorder: pre,
        table_order: table_order,
        month: moment(month).format('MMM YYYY')
      }
    })

    setDataSource(data)
  }

  useEffect(() => {
    loadAll()
  }, [monthlyBins])

  const fetchPartners = (data) => {
    return []
      .concat(
        data.map((d) => {
          return {
            month: d.month,
            value: d.merchants,
            category: 'Partners'
          }
        })
      )
      .concat(
        data.map((d) => {
          return {
            month: d.month,
            value: d.outlets,
            category: 'Outlets'
          }
        })
      )
  }

  const fetchIncome = (data) => {
    return []
      .concat(
        data.map((d) => {
          return {
            month: d.month,
            value: d.commissions,
            category: 'Commissions'
          }
        })
      )
      .concat(
        data.map((d) => {
          return {
            month: d.month,
            value: d.courier_fee,
            category: 'Courier Fee'
          }
        })
      )
      .concat(
        data.map((d) => {
          return { month: d.month, value: d.slerp_pay, category: 'Slerp Pay' }
        })
      )
      .concat(
        data.map((d) => {
          return { month: d.month, value: d.all_income, category: 'All Income' }
        })
      )
  }

  const fetchGMVGTV = (data) => {
    return []
      .concat(
        data.map((d) => {
          return {
            month: d.month,
            value: d.gmv,
            category: 'GMV'
          }
        })
      )
      .concat(
        data.map((d) => {
          return {
            month: d.month,
            value: d.gtv,
            category: 'GTV'
          }
        })
      )
  }

  return (
    <Layout style={{ maxWidth: 1400 }}>
      <Row align='middle'>
        <Col>
          <PageHeader title='Filter by date' />
        </Col>
        <Col>
          <RangePicker picker='month' onChange={handleRangePicker} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xl={24}>
          <PageHeader title='GMV' />
          {dataSource && (
            <GenericChart
              data={fetchGMVGTV(dataSource)}
              field='value'
              series='category'
              type='area'
            />
          )}
        </Col>
      </Row>

      <Divider />

      <Row gutter={24}>
        <Col xl={12}>
          <PageHeader title='Partners' />
          {dataSource && (
            <GenericChart
              data={fetchPartners(dataSource)}
              field='value'
              series='category'
              type='area'
            />
          )}
        </Col>
        <Col xl={12}>
          <PageHeader title='Income' />
          {dataSource && (
            <GenericChart
              data={fetchIncome(dataSource)}
              field='value'
              series='category'
              type='area'
            />
          )}
        </Col>
      </Row>
      <Divider />
      <Divider />
      <Row gutter={24}>
        <Col xl={8}>
          <PageHeader title='Transactions' />
          {dataSource && (
            <GenericChart data={dataSource} field='transactions' type='area' />
          )}
        </Col>
        <Col xl={8}>
          <PageHeader title='Average GMV Per Day' />
          {dataSource && (
            <GenericChart data={dataSource} field='avg_per_day' type='area' />
          )}
        </Col>
        <Col xl={8}>
          <PageHeader title='Average Basket Size' />
          {dataSource && (
            <GenericChart
              data={dataSource}
              field='avg_basket_size'
              type='area'
            />
          )}
        </Col>
      </Row>
    </Layout>
  )
}

export default Summaries
