import React from 'react'
import { Subscription } from '@apollo/client/react/components'
import { PageHeader, Empty, Result, Skeleton } from 'antd'
import OrderDetails from './OrderDetails'
import { QUERY_ORDER_TRACKING_TOKEN, SUBSCRIBE_ORDER } from './OrderQueries'
import { useQuery } from '@apollo/client'

interface OrderInfoProps {
  order: any
  client: any
}

const OrderInfo = (props: OrderInfoProps) => {
  const { order, client } = props

  // Gets order tracking token so ops can view order tracking page
  const {
    data: { getOrderTrackingToken } = {},
    loading: isRetrievingTrackingToken
  } = useQuery(QUERY_ORDER_TRACKING_TOKEN, {
    skip: !order,
    variables: {
      transactionId: order?.transaction_id
    }
  })

  if (order === null || isRetrievingTrackingToken) {
    return <Empty />
  }

  return (
    <>
      <PageHeader title={`Order #${order.transaction_id}`} />
      <Subscription
        subscription={SUBSCRIBE_ORDER}
        variables={{ transactionId: order.transaction_id }}
      >
        {({ data, error, loading }) => {
          if (loading) {
            return <Skeleton active />
          }
          if (error || (data && data.message === null)) {
            return <Result status='500' title='500' subTitle={error.message} />
          }
          if (data && data.orders && data.orders[0]) {
            return (
              <OrderDetails
                order={data.orders[0]}
                client={client}
                orderTrackingToken={getOrderTrackingToken}
              />
            )
          } else {
            return (
              <Result
                status='404'
                title='Invalid order'
                subTitle="This order doesn't exit"
              />
            )
          }
        }}
      </Subscription>
    </>
  )
}

export default OrderInfo
