import { useHistory } from 'react-router-dom'

type OrderLinkProps = {
  text: string
  onClick: React.MouseEventHandler<HTMLAnchorElement>
  basePath?: string
}

const OrderLink = ({ text, onClick, basePath }: OrderLinkProps) => {
  const history = useHistory()

  const click = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`${basePath || ''}/orders/${text}`)
    return onClick(e)
  }

  return (
    <a href={`${basePath || ''}/orders/${text}`} onClick={click}>
      {text}
    </a>
  )
}

export default OrderLink
