import React, { useRef, useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { v4 as uuidv4 } from 'uuid'
import {
  Col,
  Form,
  Input,
  Button,
  Row,
  Select,
  Space,
  notification
} from 'antd'
import { Store as FormStore } from 'antd/lib/form/interface'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import converter from 'csvtojson'
import { isEmpty } from 'lodash'
import { ApolloClient } from '@apollo/client'
import PricingTable from './PricingTable'
import { requiredRule } from './rules'
import { CREATE_AND_ASSIGN_TO_STORES_PRICE_MATRIX } from '../MerchantQueries'
import { ReadRateCardImportFile, ValidateRateCardPayload } from '../utils'

interface Store {
  id: string
  name: string
}

interface RateCardFormProps {
  merchantId: string
  merchantStores: Store[]
  availableStores: Array<string>
  client: ApolloClient<object>
  onSaveCallback?: () => void
}

interface PriceMatrix {
  car: { [key: number]: string }
  bike: { [key: number]: string }
  motorbike: { [key: number]: string }
}

const { useForm } = Form
const { Option } = Select

const RateCardForm = ({
  merchantId,
  merchantStores,
  availableStores,
  client,
  onSaveCallback
}: RateCardFormProps) => {
  const [createPriceMatrix, { loading }] = useMutation(
    CREATE_AND_ASSIGN_TO_STORES_PRICE_MATRIX,
    {
      fetchPolicy: 'no-cache',
      client: client
    }
  )

  const [form] = useForm()
  const uploadButtonRef = useRef(null)
  const [
    priceMatrixDetails,
    setPriceMatrixDetails
  ] = useState<PriceMatrix | null>(null)
  const [rateCardName, setRateCardName] = useState<string>('')

  useEffect(() => {
    form.setFieldsValue({
      name: rateCardName
    })
  }, [rateCardName])

  const createRateCard = (values: FormStore) => {
    const { name, stores } = values
    const priceMatrixId = uuidv4()
    const newMatrix = {
      merchant_id: merchantId,
      id: priceMatrixId,
      inserted_at: new Date(),
      updated_at: new Date(),
      data: priceMatrixDetails,
      units: 'km',
      name,
      global: false
    }

    createPriceMatrix({
      variables: { object: newMatrix, priceMatrixId, storeIds: stores }
    }).then((result) => {
      onSaveCallback && onSaveCallback()
    })
  }

  const importRateCard = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    if (!file) return
    const fileName = file.name.slice(0, -4)
    const rawCsvRows = await ReadRateCardImportFile(file)
    const csvRows = await converter({ flatKeys: true }).fromString(
      rawCsvRows as string
    )
    const payload = {
      bike: csvRows[0],
      motorbike: csvRows[1],
      car: csvRows[2]
    }

    // validate payload
    const result = ValidateRateCardPayload(payload)
    if (!result.valid) {
      notification.warning({
        message: 'Price Matrices',
        duration: 10,
        description: result.errorMessages
      })
      return
    }

    setRateCardName(fileName)
    setPriceMatrixDetails(payload)
  }

  const FileUploadInput = () => (
    <input
      ref={uploadButtonRef}
      hidden
      type='file'
      accept='.csv'
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => importRateCard(e)}
    />
  )

  return (
    <>
      <FileUploadInput />
      {isEmpty(priceMatrixDetails) && (
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => uploadButtonRef.current.click()}
        >
          UPLOAD RATE CARD
        </Button>
      )}
      {!isEmpty(priceMatrixDetails) && (
        <Form
          data-testid='rate-card-form'
          form={form}
          onFinish={createRateCard}
          requiredMark={false}
          labelCol={{ span: 4 }}
          labelAlign='left'
        >
          <Row>
            <Col span={8}>
              <Form.Item name='name' label='Name'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item name='stores' label='Stores' rules={requiredRule}>
                <Select
                  mode='multiple'
                  style={{ width: '100%' }}
                  placeholder='select store/s'
                >
                  {merchantStores.map((store: Store) => {
                    const disabled = !availableStores.includes(store.id)
                    return (
                      <Option
                        key={uuidv4()}
                        disabled={disabled}
                        value={store.id}
                        label={store.name}
                      >
                        {store.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Space direction='vertical'>
            {priceMatrixDetails && (
              <PricingTable priceMatrix={priceMatrixDetails} />
            )}
            <Button
              loading={loading}
              type='primary'
              icon={priceMatrixDetails ? <EditOutlined /> : <PlusOutlined />}
              onClick={() => uploadButtonRef.current.click()}
            >
              EDIT RATE CARD
            </Button>
          </Space>

          <Row justify='end' gutter={[8, 0]}>
            <Col>
              <Button
                loading={loading}
                onClick={() => setPriceMatrixDetails(null)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                loading={loading}
                key='submit'
                type='primary'
                htmlType='submit'
                disabled={isEmpty(priceMatrixDetails)}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}

export default RateCardForm
