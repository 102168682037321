/* Utility functions for sanitization of graphql data */
// @ts-nocheck

export const zeroedSeries = (year, upTo = 12) => {
  let series = {}
  for (let m = 1; m <= 12; m++) {
    series[[year, m]] = m <= upTo ? 0 : null
  }
  return series
}

export const monthKey = (item) => {
  return item.month
}

export const yearMonthKey = (item) => {
  return [item.year, item.month]
}

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const monthlySeries = (
  items,
  year,
  { key = yearMonthKey, valueKey = 'count' } = {}
) => {
  let series = {}
  let maxMonth

  items.forEach((item) => {
    series[key(item)] = item[valueKey]
    maxMonth = item.month
  })

  const zeroed = zeroedSeries(year, maxMonth)
  return { ...zeroed, ...series }
}

export const cumulativeSeries = (items) => {
  let count = 0
  let series = {}
  for (const key in items) {
    if (items[key] == null) {
      series[key] = null
    } else {
      count += items[key]
      series[key] = count
    }
  }
  return series
}
