import { gql } from '@apollo/client'

const QUERY_MERCHANT_LIST = gql`
  query merchantsList {
    merchants(order_by: { published_at: asc_nulls_last }) {
      id
      orders_aggregate {
        aggregate {
          count
        }
      }
      name
      slug
      published_at
      stores_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const SUBSCRIPTION_ORDER_STATS = gql`
  subscription getOrdersSummary {
    view_orders_summary {
      today
      sales_today
      this_month
      sales_month
      this_week
      sales_week
    }
  }
`

const QUERY_DAILY_ORDERS = gql`
  query dailyOrders {
    view_monthly_orders_summary {
      count
      day
      total
    }
  }
`

const SUBSCRIPTION_LATEST_ORDERS = gql`
  query ordersList {
    orders(order_by: { inserted_at: desc }, limit: 10) {
      merchant {
        name
      }
      transaction_id
      store {
        name
      }
      total
      fulfillment_type
      fulfillment_date
      status
      inserted_at
      customer_details
      create_device_category
      create_platform
    }
  }
`

const QUERY_MERCHANT_STORE_STATS = gql`
  query merchantStoreStats($limit: Int! = 10) {
    view_merchant_store_status(limit: $limit) {
      id
      name
      slug
      open
      total_orders
      pending_orders
      closed
      current_plan
      last_ordered_at
    }
  }
`

const QUERY_WEEKLY_ORDER_STATUSES = gql`
  query weeklyOrderStatuses {
    view_order_weekly_statuses {
      count
      status
    }
  }
`

export const QUERY_HOURLY_ORDERS = gql`
  query hourlyOrders {
    view_hourly_orders {
      count
      hour
      gtv
      gmv
    }
  }
`

export {
  QUERY_MERCHANT_LIST,
  SUBSCRIPTION_ORDER_STATS,
  SUBSCRIPTION_LATEST_ORDERS,
  QUERY_DAILY_ORDERS,
  QUERY_MERCHANT_STORE_STATS,
  QUERY_WEEKLY_ORDER_STATUSES
}
