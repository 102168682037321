import { gql, useApolloClient } from '@apollo/client'
import { createClient } from '../../client'

// 1 hour
const DEFAULT_EXPIRY = 60 * 30
const CONTROLS_HOST = process.env.REACT_APP_CONTROLS_HOST
const MERCHANT_AUTH = gql`
  query getMerchantToken($merchantId: ID!, $userId: ID!) {
    merchantAuth(merchantId: $merchantId, userId: $userId) {
      expiresAt
      token
    }
  }
`

const REFRESH_MERCHANT_TOKEN = gql`
  query refreshMerchantToken($expiry: Int!) {
    refreshToken(expiry: $expiry) {
      expiresAt
      token
    }
  }
`

type MerchantAuth = {
  expiresAt: Date
  token: string
}

type MerchantAuthResponse = {
  merchantAuth: MerchantAuth
}

export const useMerchantAuth = () => {
  const client = useApolloClient()

  const getToken = async (merchantId: string, userId: string) => {
    const response = await client.query<MerchantAuthResponse>({
      query: MERCHANT_AUTH,
      variables: {
        merchantId,
        userId
      },
      fetchPolicy: 'no-cache'
    })

    return response.data.merchantAuth
  }

  const refreshToken = async (token: string, expiry: number) => {
    const merchantClient = createClient(token)
    const response = await merchantClient.query({
      query: REFRESH_MERCHANT_TOKEN,
      variables: {
        expiry: expiry
      }
    })

    return response.data.refreshToken
  }

  const merchantAdminSSOUrl = async (merchant: {
    id: string
    users: {
      id: string
      email: string
      role: string
    }[]
  }) => {
    const [admin] = merchant.users.filter((mu) => mu.role === 'admin')
    if (admin?.id) {
      // Refresh token since we only have 5 mins for the handover
      const { token } = await getToken(merchant.id, admin?.id)
      const { token: ssoToken } = await refreshToken(token, DEFAULT_EXPIRY)
      return `${CONTROLS_HOST}/sso?merchant=${merchant.id}&token=${ssoToken}`
    }
  }

  const merchantUserSSOUrl = async ({
    merchantId,
    userId
  }: {
    merchantId: string
    userId: string
  }) => {
    // Refresh token since we only have 5 mins for the handover
    const { token } = await getToken(merchantId, userId)
    const { token: ssoToken } = await refreshToken(token, DEFAULT_EXPIRY)
    return `${CONTROLS_HOST}/sso?merchant=${merchantId}&token=${ssoToken}`
  }

  return {
    getToken,
    refreshToken,
    merchantAdminSSOUrl,
    merchantUserSSOUrl
  }
}
