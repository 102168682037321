import React, { useState } from 'react'
import { Col, Row, Switch, Tooltip, notification } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { v4 as uuidv4 } from 'uuid'

import {
  FETCH_INTEGRATION_SETTINGS,
  INSERT_INTEGRATION_SETTINGS,
  UPDATE_INTEGRATION_SETTINGS
} from '../StoreQueries'

export type IntegrationSettings = {
  id: string
  customer_anonymisation_delivery: boolean
  store_id: string
}

const IntegrationForm = ({ store, client }) => {
  const initIntegrationSettings = {
    id: '',
    customer_anonymisation_delivery: false,
    store_id: store.id
  }

  const [
    integrationSettings,
    setIntegrationSettings
  ] = useState<IntegrationSettings>(initIntegrationSettings)

  const { loading } = useQuery(FETCH_INTEGRATION_SETTINGS, {
    variables: { storeId: store.id },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setIntegrationSettings(
        data.store_integration_settings[0]
          ? data.store_integration_settings[0]
          : integrationSettings
      )
    }
  })

  const [insertIntegrationSettings] = useMutation(INSERT_INTEGRATION_SETTINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIntegrationSettings(data.insert_store_integration_settings_one)

      notification['success']({
        message: `${store.name} integration settings updated!`
      })
    },
    onError: (error) => {
      notification['error']({
        message: 'Something went wrong!'
      })
    }
  })

  const [updateIntegrationSettings] = useMutation(UPDATE_INTEGRATION_SETTINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIntegrationSettings(data.update_store_integration_settings_by_pk)

      notification['success']({
        message: `${store.name} integration settings updated!`
      })
    },
    onError: (error) => {
      notification['error']({
        message: 'Something went wrong!'
      })
    }
  })

  const handleIntegrationSettingsChange = (integrationSettings, value) => {
    if (integrationSettings.id === '') {
      const input = {
        id: uuidv4(),
        store_id: integrationSettings.store_id,
        customer_anonymisation_delivery: value,
        inserted_at: new Date(),
        updated_at: new Date()
      }

      insertIntegrationSettings({ variables: { input: input } })
    } else {
      updateIntegrationSettings({
        variables: {
          id: integrationSettings.id,
          customerAnonymisationDelivery: value
        }
      })
    }
  }

  return (
    <Row justify='space-between'>
      <Col>
        Anonymise Customer (Delivery)
        <Tooltip title='Enabling this setting will send anonymised customer details to any 3rd party middleware (Deliverect / Urban Piper) for Delivery orders through Dispatch. Self-deliveries will be unaffected.'>
          <QuestionCircleOutlined />
        </Tooltip>
      </Col>
      <Col>
        <Switch
          loading={loading}
          checked={integrationSettings.customer_anonymisation_delivery}
          onChange={(value) => {
            handleIntegrationSettingsChange(integrationSettings, value)
          }}
        />
      </Col>
    </Row>
  )
}

export default IntegrationForm
