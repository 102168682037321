import React from 'react'
import { Drawer } from 'antd'
import OrderInfo from './OrderInfo'
import { useMediaQuery } from 'react-responsive'

const OrderDrawer = ({ order, onClose, visible, client }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  return (
    <Drawer
      width={isTabletOrMobileDevice ? '100%' : '50%'}
      onClose={onClose}
      visible={visible}
    >
      <OrderInfo {...{ client, order }} />
    </Drawer>
  )
}

export default OrderDrawer
