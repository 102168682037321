import { gql } from '@apollo/client'

export const GMV_TODAY = gql`
  query gmvToday($startDate: timestamptz!, $endDate: timestamptz!) {
    gmv_today: view_cc_gmv(
      where: { timeframe: { _gte: $startDate, _lte: $endDate } }
      order_by: { timeframe: desc }
      limit: 2
    ) {
      timeframe
      total
      subtotal
      gmv
      transactions
      partners
      stores
    }
  }
`

export const DAILY_BINS = gql`
  query monthlyBins($startDate: date!, $endDate: date!) {
    daily_bins(
      where: { day: { _gte: $startDate, _lte: $endDate } }
      order_by: { day: asc }
    ) {
      day
      total
      subtotal
      gmv
      gtv
      transactions
      partners
      stores
      discounts
      application_fee
    }
  }
`
export const MONTHLY_BINS = gql`
  query MonthlyBins($startDate: date!, $endDate: date!) {
    view_monthly_kpi_totals(
      where: { month: { _gte: $startDate, _lte: $endDate } }
      order_by: { month: asc }
    ) {
      month
      slerp_fee
      courier_fee
      application_fee
      gmv
      gtv
      self_fulfilled
      pickup
      table_order
      last_mile
      asap
      ssd
      pre
      stores
      count
      merchants
    }
  }
`
