import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/link-ws'
import jwt from 'jsonwebtoken'

const HASURA_GRAPHQL_ENGINE_HOSTNAME =
  process.env.REACT_APP_GRAPH_HOST || 'partner.api.slerp.com'

export const GRAPHQL_ENDPOINT = `https://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`
export const WEBSOCKET_ENDPOINT = `wss://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`

const token = localStorage.getItem('token')

const httpLink = (uri: string, token: string) =>
  new HttpLink({
    uri: uri,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

// Make WebSocketLink with appropriate url
const wsLink = (uri: string, token: string) => {
  return new WebSocketLink({
    uri: uri,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          // 'x-hasura-admin-secret': HASURA_TOKEN,
          Authorization: `Bearer ${token}`
        }
      }
    }
  })
}

const splitLink = (apiKey = undefined || '') =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink(WEBSOCKET_ENDPOINT, apiKey),
    httpLink(GRAPHQL_ENDPOINT, apiKey)
  )

/**
 * This creates a new Apollo Client.
 * This is being used by SlerpClient or can be called as a standalone function
 *
 * @param domain
 * @param apiKey
 */
export const createClient = (apiKey: string) => {
  return new ApolloClient({
    link: splitLink(apiKey),
    cache: new InMemoryCache({
      addTypename: false
    })
  })
}

export const validToken = () => {
  const jwttoken = jwt.decode(token)
  return jwttoken && jwttoken.exp < new Date().getTime() ? true : false
}

export default createClient(token)
