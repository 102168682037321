import React, { useState } from 'react'
import { Table, PageHeader, Tag } from 'antd'
import OrderDrawer from '../Orders/OrderDrawer'
import 'moment-timezone'
import Moment from 'react-moment'
import { OrderStatusTag } from '../Orders/OrderStatuses'
import OrderLink from '../Orders/OrderLink'
interface OrdersListProps {
  orders: any
  client: any
}

const OrdersList = (props: OrdersListProps) => {
  const { client, orders } = props
  const [activeOrder, setActiveOrder] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)

  const showDrawer = (record) => {
    setActiveOrder(record)
    setDrawerVisible(true)
  }
  const onDrawerClose = () => {
    setActiveOrder(null)
    setDrawerVisible(false)
  }

  const columns = [
    {
      title: 'Transaction Ref',
      dataIndex: 'transaction_id',
      key: 'transaction_id',
      render: (text) => (
        <OrderLink
          text={text}
          onClick={() => {
            showDrawer(text)
          }}
        />
      )
    },
    {
      title: 'Store',
      dataIndex: ['store', 'name'],
      key: 'store.name',
      render: (text, record) => (
        <>
          <b>{record.merchant.name}</b> {record.store.name}
        </>
      )
    },
    {
      title: 'Type',
      dataIndex: 'fulfillment_type',
      key: 'fulfillment_type'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: OrderStatusTag
    },
    {
      title: 'Expected At',
      dataIndex: 'fulfillment_date',
      key: 'fulfillment_date',
      render: (text, record) => {
        return (
          (record.fulfillment_date && (
            <>
              <Moment format='DD/MM/YY'>{text}</Moment>{' '}
              {record.fulfillment_time_range && (
                <> ({record.fulfillment_time_range})</>
              )}
            </>
          )) || <Tag color='red'>ASAP</Tag>
        )
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => {
        return <span>£{text}</span>
      }
    },
    {
      title: 'Ordered At',
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      render: (text) => {
        return <Moment>{text}</Moment>
      }
    }
  ]

  return (
    <>
      <PageHeader title='Latest Orders' />

      <Table
        dataSource={orders}
        columns={columns}
        pagination={{ defaultPageSize: 40 }}
        tableLayout='auto'
      />
      <OrderDrawer
        order={activeOrder}
        client={client}
        visible={drawerVisible}
        onClose={onDrawerClose}
      />
    </>
  )
}

export default OrdersList
