import React, { useState } from 'react'
import {
  Table,
  Form,
  PageHeader,
  Tag,
  Button,
  Modal,
  message,
  Select
} from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'
import {
  QUERY_INTEGRATORS,
  ADD_PARTNER_MERCHANT,
  QUERY_MERCHANTS
} from './IntegratorsQueries'
import JSONView from '../Utils/JSONView'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

const IntegrationsContainer = (props) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const { client } = props
  const { loading, data, refetch } = useQuery(QUERY_INTEGRATORS, {
    client: client,
    fetchPolicy: 'no-cache'
  })

  const { data: merchantData } = useQuery(QUERY_MERCHANTS, {
    client: client,
    notifyOnNetworkStatusChange: true
  })
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [modalPartner, setModalPartner] = useState(null)

  const columns = [
    {
      title: 'Integrator',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Webhook',
      dataIndex: 'webhook',
      key: 'webhook'
    },
    {
      title: 'Raw Settings',
      dataIndex: 'settings',
      key: 'settings',
      render: (text, record) => {
        return record.settings && <JSONView src={record.settings} />
      }
    },
    {
      title: 'Merchants',
      dataIndex: 'partners_merchants',
      key: 'merchants',
      render: (text, record) => {
        const merchants = record.partners_merchants.map(
          (partner_merchant) => partner_merchant.merchant.slug
        )
        return merchants.map((slug) => (
          <>
            <Tag color='purple'>{`${slug}`}</Tag>
            <span></span>
          </>
        ))
      }
    },
    {
      dataIndex: 'add_merchant',
      key: 'add_merchant',
      render: (text, record) => {
        return (
          <Button type='primary' onClick={() => openAddModal(record)}>
            Add Merchant
          </Button>
        )
      }
    }
  ]

  const [addMerchant] = useMutation(ADD_PARTNER_MERCHANT, { client })

  const handleAddMerchant = (partnerId, merchantId) => {
    const params = {
      variables: {
        id: uuidv4(),
        merchantId: merchantId,
        partnerId: partnerId,
        insertedAt: moment().format(),
        updatedAt: moment().format()
      }
    }

    addMerchant(params)
      .then(() => {
        refetch()
        setAddModalVisible(false)
        setModalPartner(null)
        message.destroy()
        message.success('Successfully linked merchant to integrator')
      })
      .catch((err) => {
        message.destroy()
        message.error('Failed to link merchant to integrator')
      })
      .finally(() => {})
  }

  const openAddModal = (partner) => {
    setAddModalVisible(true)
    setModalPartner(partner)
  }

  const closeAddModal = () => {
    setAddModalVisible(false)
    setModalPartner(null)
  }

  return (
    <>
      <PageHeader
        title='Integrators'
        subTitle='This shows information for integrators(embargo and otter)'
      />
      <Table
        dataSource={data?.partners}
        columns={columns}
        pagination={{ defaultPageSize: 40 }}
        size={isTabletOrMobileDevice ? 'small' : 'middle'}
        tableLayout='auto'
        loading={loading}
      />
      {modalPartner && (
        <AddMerchantModal
          partner={modalPartner}
          visible={addModalVisible}
          onModalClose={closeAddModal}
          onMerchantAdd={handleAddMerchant}
          merchants={merchantData.merchants}
        />
      )}
    </>
  )
}

const AddMerchantModal = (props) => {
  const [selectedMerchantId, setSelectedMerchantId] = useState(null)
  const partnerMerchantIds = props.partner.partners_merchants.map(
    (partner_merchant) => partner_merchant.merchant.id
  )
  const validMerchants = props.merchants.filter(
    (merchant) => !merchant.id.includes(partnerMerchantIds)
  )
  return (
    <Modal
      title={`Link merchant to ${props?.partner?.name}`}
      visible={props?.visible}
      okText={'Save'}
      onOk={() => props.onMerchantAdd(props?.partner?.id, selectedMerchantId)}
      onCancel={props.onModalClose}
    >
      <Form>
        <Form.Item label='Merchant'>
          <Select
            showSearch
            onChange={(merchantId) => {
              setSelectedMerchantId(merchantId)
              return true
            }}
            style={{ width: 250 }}
            filterOption={(input, option) =>
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {validMerchants.map((merchant) => (
              <Select.Option value={merchant.id}>{merchant.slug}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default IntegrationsContainer
