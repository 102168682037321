import { gql } from '@apollo/client'

export const QUERY_MERCHANTS = gql`
  query merchantStoreStats(
    $sector: String
    $published: Boolean = true
    $limit: Int! = 10
  ) {
    merchants(
      where: {
        sector: { _eq: $sector }
        _not: { published_at: { _is_null: $published } }
      }
      limit: $limit
    ) {
      id
      name
      slug
      published_at
      inserted_at
      setting
      stores {
        id
        slug
      }
    }
  }
`
