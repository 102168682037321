import { useState } from 'react'
import {
  message,
  Button,
  Col,
  InputNumber,
  Input,
  Form,
  Row,
  PageHeader,
  Popconfirm,
  Select
} from 'antd'
import { useMutation } from '@apollo/react-hooks'
import {
  CREATE_TRANSACTION_PLAN,
  TRANSACTION_PLAN_VALUE_VALIDATOR
} from './Queries'
import { Link } from 'react-router-dom'
import { required } from './Rules'
import { Decimal } from 'decimal.js'
import { v4 as uuidv4 } from 'uuid'

const { useForm } = Form

const TransactionPlanForm = ({ client }) => {
  const [form] = useForm()

  const [createTransactionPlan] = useMutation(CREATE_TRANSACTION_PLAN, {
    client
  })

  const stripe_account_types = [
    { label: 'Slerp Pay', value: 'slerp_pay' },
    { label: 'Standard', value: 'standard' }
  ]

  const uniqueValues = [
    ({ getFieldValue }) => ({
      validator: (_: any) => {
        const stripe_account_type = getFieldValue('stripe_account_type')
        const fixedFee = getFieldValue('fixed_fee')
        const percentage = getFieldValue('percentage')
          ? percentageToNumber(getFieldValue('percentage'))
          : 0
        const amexPercentage = getFieldValue('amex_percentage')
          ? percentageToNumber(getFieldValue('amex_percentage'))
          : 0
        const internationalPercentage = getFieldValue(
          'international_percentage'
        )
          ? percentageToNumber(getFieldValue('international_percentage'))
          : 0

        return client
          .query({
            query: TRANSACTION_PLAN_VALUE_VALIDATOR,
            variables: {
              fixedFee,
              percentage,
              amexPercentage,
              internationalPercentage,
              stripe_account_type
            },
            errorPolicy: 'ignore',
            fetchPolicy: 'no-cache'
          })
          .then((result) => {
            if (result?.data?.transaction_plans?.length > 0) {
              return Promise.reject(
                'A transaction plan with these values alread exist. Please update the values or use the existing plan.'
              )
            }
            return Promise.resolve()
          })
          .catch((error) => {
            return Promise.reject(error)
          })
      }
    })
  ]

  const [submitLoading, setSubmitLoading] = useState(false)

  const percentageToNumber = (value) => {
    const decimal = new Decimal(value)
    return decimal.dividedBy(100).toDecimalPlaces(4).toNumber()
  }

  const parsePercentage = (percentage: string) => {
    const stringNum = percentage?.replace('%', '') || 0
    const value = new Decimal(Number(stringNum))
    return value.toDecimalPlaces(2).toNumber()
  }

  const createPlan = (values) => {
    setSubmitLoading(true)
    message.info('Creating transaction plan...')

    const percentage = percentageToNumber(values.percentage)
    const amexPercentage = percentageToNumber(values.amex_percentage)
    const internationalPercentage = percentageToNumber(
      values.international_percentage
    )

    const params = {
      ...values,
      percentage: percentage,
      amex_percentage: amexPercentage,
      international_percentage: internationalPercentage
    }

    form
      .validateFields()
      .then(() =>
        createTransactionPlan({
          variables: { ...params, id: uuidv4() }
        })
          .then(() => {
            message.destroy()
            message.success('Transaction plan successfully created!', 1)
            window.setTimeout(() => {
              window.location.pathname = '/transaction-plans'
            }, 1000)
          })
          .catch((error) => {
            setSubmitLoading(false)
            message.destroy()
            message.error(
              `Unable to create commission plan. Please check errors below and try again or contact tech support. Error: ${error}`,
              10
            )
          })
      )
      .catch(() => {
        message.destroy()
        message.error(
          `Unable to create commission plan. Please check errors below and try again or contact tech support.`,
          10
        )
      })
  }

  return (
    <>
      <PageHeader title='Create new transaction plan' />
      <Form
        form={form}
        onFinish={createPlan}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
        layout='vertical'
        scrollToFirstError={true}
        initialValues={{
          name: '',
          percentage: 0,
          amex_percentage: 0,
          international_percentage: 0,
          fixed_fee: 0,
          stripe_account_type: 'slerp_pay'
        }}
      >
        <Form.Item name='name' label='Name' rules={[...required]}>
          <Input type='text' maxLength={160} />
        </Form.Item>
        <Form.Item
          name='stripe_account_type'
          label='Type'
          rules={[...required]}
        >
          <Select defaultValue='slerp_pay'>
            {stripe_account_types.map((t) => (
              <Select.Option key={t.value} value={t.value}>
                {t.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='percentage'
          label='Percentage'
          rules={[...required, ...uniqueValues]}
        >
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            defaultValue={0}
            parser={parsePercentage}
            required
          />
        </Form.Item>
        <Form.Item
          name='amex_percentage'
          label='Amex Percentage'
          rules={[...required, ...uniqueValues]}
        >
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            defaultValue={0}
            parser={parsePercentage}
            required
          />
        </Form.Item>
        <Form.Item
          name='international_percentage'
          label='International Percentage'
          rules={[...required, ...uniqueValues]}
        >
          <InputNumber
            min={0}
            max={100}
            defaultValue={0}
            formatter={(value) => `${value}%`}
            parser={parsePercentage}
            required
          />
        </Form.Item>
        <Form.Item
          name='fixed_fee'
          label='Fixed Fee'
          rules={[...required, ...uniqueValues]}
        >
          <InputNumber min={0} defaultValue={0} required />
        </Form.Item>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Link to='/transaction-plans' style={{ marginRight: '16px' }}>
                <Button type='ghost'>Discard</Button>
              </Link>
              <Popconfirm
                title='Are you sure you want to create this plan?'
                onConfirm={() => {
                  form.submit()
                }}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={submitLoading}
                >
                  Create
                </Button>
              </Popconfirm>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default TransactionPlanForm
