import React from 'react'
import SmsUtil from './SmsUtil'
import { PageHeader, Row, Col } from 'antd'

const SMSContainer = () => {
  return (
    <>
      <PageHeader title='Send SMS Tool' />

      <Row>
        <Col span='16'>
          <SmsUtil loadRecent={true} />
        </Col>
      </Row>
    </>
  )
}

export default SMSContainer
