import { gql } from '@apollo/client'

const QUERY_TRANSACTION_PLANS = gql`
  query allTransactionPlans {
    transaction_plans(order_by: { name: asc }) {
      id
      name
      percentage
      amex_percentage
      international_percentage
      fixed_fee
      defaulted_at
      stripe_account_type
    }
  }
`

const QUERY_TRANSACTION_PLANS_BY_TYPE = gql`
  query allTransactionPlans(
    $stripeAccountType: String!
    $currentPlanId: uuid!
  ) {
    transaction_plans(
      where: {
        _or: [
          { stripe_account_type: { _eq: $stripeAccountType } }
          { id: { _eq: $currentPlanId } }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      percentage
      amex_percentage
      international_percentage
      fixed_fee
      defaulted_at
      stripe_account_type
    }
  }
`

const GET_MERCHANT_PLAN = gql`
  query getMerchantPlan($merchantId: uuid!) {
    transaction_plans(where: { merchant: { id: { _eq: $merchantId } } }) {
      id
      name
      percentage
      amex_percentage
      international_percentage
      fixed_fee
      defaulted_at
    }
  }
`

const CREATE_TRANSACTION_PLAN = gql`
  mutation createTransactionPlan(
    $id: uuid!
    $name: String!
    $percentage: numeric!
    $amex_percentage: numeric!
    $international_percentage: numeric!
    $fixed_fee: numeric!
    $stripe_account_type: String!
  ) {
    transaction_plan: insert_transaction_plans_one(
      object: {
        id: $id
        inserted_at: "now()"
        updated_at: "now()"
        name: $name
        percentage: $percentage
        amex_percentage: $amex_percentage
        international_percentage: $international_percentage
        fixed_fee: $fixed_fee
        stripe_account_type: $stripe_account_type
      }
    ) {
      id
    }
  }
`

const UPDATE_DEFAULT_TRANSACTION_PLAN = gql`
  mutation defaultTransactionPlan($id: uuid!) {
    old_transaction_plan: update_transaction_plans(
      where: { defaulted_at: { _is_null: false } }
      _set: { defaulted_at: null }
    ) {
      affected_rows
      returning {
        id
        defaulted_at
      }
    }
    current_transaction_plan: update_transaction_plans_by_pk(
      pk_columns: { id: $id }
      _set: { defaulted_at: "now()" }
    ) {
      id
      defaulted_at
    }
  }
`

const UPDATE_MERCHANT_TRANSACTION_PLAN = gql`
  mutation updateMerchantTransactionPlan($merchantId: uuid!, $planId: uuid!) {
    merchant: update_merchants_by_pk(
      pk_columns: { id: $merchantId }
      _set: { transaction_plan_id: $planId }
    ) {
      id
      transaction_plan_id
    }
  }
`

const TRANSACTION_PLAN_VALUE_VALIDATOR = gql`
  query validateTranasactionValue(
    $percentage: numeric!
    $amexPercentage: numeric!
    $internationalPercentage: numeric!
    $fixedFee: numeric!
    $stripe_account_type: String!
  ) {
    transaction_plans(
      where: {
        percentage: { _eq: $percentage }
        amex_percentage: { _eq: $amexPercentage }
        international_percentage: { _eq: $internationalPercentage }
        fixed_fee: { _eq: $fixedFee }
        stripe_account_type: { _eq: $stripe_account_type }
      }
    ) {
      id
    }
  }
`

export {
  QUERY_TRANSACTION_PLANS,
  CREATE_TRANSACTION_PLAN,
  UPDATE_DEFAULT_TRANSACTION_PLAN,
  UPDATE_MERCHANT_TRANSACTION_PLAN,
  GET_MERCHANT_PLAN,
  TRANSACTION_PLAN_VALUE_VALIDATOR,
  QUERY_TRANSACTION_PLANS_BY_TYPE
}
