import { useState, useEffect } from 'react'

interface Time {
  hours: number
  minutes: number
  seconds: number
}

const Clock = () => {
  const [time, setTime] = useState<Time>({
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date()
      setTime({
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds()
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const padTime = (time: number): string => {
    return time < 10 ? `0${time}` : `${time}`
  }

  return (
    <>
      {`${padTime(time.hours)}:${padTime(time.minutes)}:${padTime(
        time.seconds
      )}`}
    </>
  )
}

export default Clock
