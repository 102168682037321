import { gql } from '@apollo/client'

const QUERY_INTEGRATORS = gql`
  query integrationPartners {
    partners {
      id
      name
      key
      settings
      webhook
      partners_merchants {
        merchant {
          slug
          id
          name
        }
      }
    }
  }
`

const QUERY_MERCHANTS = gql`
  {
    merchants(where: { published_at: { _is_null: false } }) {
      id
      name
      slug
    }
  }
`

const ADD_PARTNER_MERCHANT = gql`
  mutation insertPartnersMerchants(
    $id: uuid!
    $merchantId: uuid!
    $partnerId: uuid!
    $insertedAt: timestamp
    $updatedAt: timestamp
  ) {
    insert_partners_merchants_one(
      object: {
        id: $id
        merchant_id: $merchantId
        partner_id: $partnerId
        inserted_at: $insertedAt
        updated_at: $updatedAt
      }
    ) {
      id
    }
  }
`

export { QUERY_INTEGRATORS, ADD_PARTNER_MERCHANT, QUERY_MERCHANTS }
