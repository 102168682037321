import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { gql } from '@apollo/client'
import { Form, message, PageHeader, Input, Button } from 'antd'

const AccountForm = (props) => {
  const UPDATE_INTERNAL_NOTES = gql`
    mutation updateInternalNotes($merchantId: uuid!, $internal_notes: String!) {
      update_merchants(
        where: { id: { _eq: $merchantId } }
        _set: { internal_notes: $internal_notes }
      ) {
        affected_rows
        returning {
          setting
        }
      }
    }
  `

  const UPDATE_ACCOUNT_INFO = gql`
    mutation updateAccountInfo($merchantId: uuid!, $account_info: jsonb!) {
      update_merchants(
        where: { id: { _eq: $merchantId } }
        _set: { account_info: $account_info }
      ) {
        affected_rows
        returning {
          setting
        }
      }
    }
  `

  const [updateInternalNotes] = useMutation(UPDATE_INTERNAL_NOTES, {
    client: props.client
  })

  const [updateAccountInfo] = useMutation(UPDATE_ACCOUNT_INFO, {
    client: props.client
  })

  const [accountManager, setAccountManager] = useState(
    props.merchant.account_info?.account_owner
  )

  const [salesPerson, setSalesPerson] = useState(
    props.merchant.account_info?.sales_person
  )

  const [internalNotes, setInternalNotes] = useState(
    props.merchant.internal_notes
  )

  const submitAccountInfo = (e) => {
    e.preventDefault()
    message.loading('Updating... Please wait.')

    updateAccountInfo({
      variables: {
        merchantId: props.merchant.id,
        account_info: {
          account_manager: accountManager,
          sales_person: salesPerson
        }
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Account Info Updated', 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  const submitNotes = (e) => {
    e.preventDefault()
    message.loading('Updating Notes... Please wait.')

    updateInternalNotes({
      variables: {
        merchantId: props.merchant.id,
        internal_notes: internalNotes
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Updated', 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  return (
    <>
      <PageHeader title='Account Management' />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Account Manager'>
          <Input
            name='account_manager'
            defaultValue={props.merchant.account_info?.account_manager}
            onChange={(e) => {
              setAccountManager(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item label='Sales Person'>
          <Input
            name='sales_person'
            defaultValue={props.merchant.account_info?.sales_person}
            onChange={(e) => {
              setSalesPerson(e.target.value)
            }}
          />

          <br />
          <br />

          <Button onClick={submitAccountInfo}>Save</Button>
        </Form.Item>

        <br />
        <Form.Item label='Internal Notes'>
          <Input.TextArea
            name='internal_notes'
            defaultValue={props.merchant.internal_notes}
            onChange={(e) => {
              setInternalNotes(e.target.value)
            }}
          />
          <br />
          <br />
          <Button onClick={submitNotes}>Save</Button>
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default AccountForm
